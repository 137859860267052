import React, { useState } from "react";
import Constaint from "../../../layout/components/Constaint";
import styled from "styled-components";
import Button from "./Button";
import { FaArrowRight } from "react-icons/fa6";
import { cardData } from "./data";
import { TinyColor } from "@ctrl/tinycolor";
import CalendlyPopUp from "./CalendlyPopUp";

const ToHelp: React.FC = () => {
  const [open, setOpen] = useState(false);
  return (
    <Wrapper>
      <Constaint>
        <Content>
          <Header>
            <h2>We are here to help your business win</h2>
            <p>
              Scale your business, boost efficiency and effortlessly expand your
              client base
            </p>
            <Button
              type="button"
              text="Book a free Demo"
              onClick={() => setOpen(true)}
              inverted
              icon={<FaArrowRight />}
            />
            <CalendlyPopUp open={open} onClose={() => setOpen(false)} />
          </Header>
          <Body>
            <CardContainer>
              {cardData.map((card, index) => {
                const lighterBgColor = new TinyColor(card.color)
                  .brighten(65)
                  .toString();

                return (
                  <Card key={index}>
                    <IconWrapper
                      bgColor={card.color}
                      lighterBgColor={lighterBgColor}
                    >
                      {card.icon}
                    </IconWrapper>
                    <div>
                      <Title>{card.title}</Title>
                      <Description>{card.description}</Description>
                    </div>
                  </Card>
                );
              })}
            </CardContainer>
          </Body>
        </Content>
      </Constaint>
    </Wrapper>
  );
};

export default ToHelp;

const Wrapper = styled.div``;
const Content = styled.div`
  padding: 4rem 0;
`;

const Header = styled.div`
  width: 100%;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    display: block;
    width: 50%;
    text-align: left;
  }

  & > h2 {
    width: 90%;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1.2;
    margin: 1.5rem auto;

    @media (min-width: 768px) {
      width: 60%;
      margin: 1.12rem 0;
    }
  }

  & > p {
    width: 80%;
    font-size: 1.2rem;
    line-height: 1.6;
    margin: 1.12rem auto;

    @media (min-width: 768px) {
      width: 60%;
      margin: 1.12rem 0;
    }
  }

  & > button {
    width: max-content;
    margin: auto;

    @media (min-width: 768px) {
      margin: 0;
    }
  }
`;
const Body = styled.div``;

// Define styled components
const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 40px;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, minmax(300px, 1fr));
  }
`;
const Card = styled.div`
  background: #fff;
  border-radius: 12px;
  padding: 32px 24px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 20px;
  align-items: flex-start;

  /* @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  } */

  & > div:nth-child(2) {
    flex: 1;
  }
`;

const IconWrapper = styled.div<{ bgColor: string; lighterBgColor: string }>`
  background-color: ${({ lighterBgColor }) =>
    lighterBgColor}; /* Lighter background color */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: ${({ bgColor }) => bgColor}; /* Main icon color */
  margin-bottom: 20px;
  flex-shrink: 1;

  & > svg {
    color: ${({ bgColor }) => bgColor}; /* Main icon color */
    height: 24px;
    width: 24px;
  }
`;

const Title = styled.h3`
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Description = styled.p`
  margin: 0;
  font-size: 16px;
  color: #666;
`;

import { HiSparkles } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { WebPaths } from "../../../../routes/data";

const FiBiAuth = () => {
  const navigate = useNavigate();
  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-85 transition-opacity flex items-center justify-center z-[99999]">
      <div className="bg-white p-8 rounded-xl shadow-xl w-[90vw] sm:max-w-[500px] flex flex-col items-center">
        <div className="aspect-square rounded-full bg-[#0076E9]/5  p-8 mb-4">
          <HiSparkles size={48} color="#0076E9" />
        </div>
        <h2 className="font-bold">Log in to access FiBi</h2>
        <p className="my-4 text-sm font-medium text-center text-gray-500">
          To access FiBI and the Visa Interview Trainer, please log in to secure
          and personalize your experience. <br /> Logging in keeps your
          information safe and ensures you receive tailored response.
        </p>
        <div className="flex items-center w-full gap-x-4">
          <button
            onClick={() => navigate(WebPaths.Root)}
            className="flex-1 py-2 text-center text-gray-500 capitalize duration-300 bg-white border border-gray-200 rounded-lg hover:bg-gray-200"
          >
            cancel
          </button>
          <button
            onClick={() => navigate(WebPaths.Auth)}
            className="flex-1 text-white bg-[#0076E9] py-2 text-center rounded-lg capitalize hover:bg-[#022e58] duration-300"
          >
            log in
          </button>
        </div>
      </div>
    </div>
  );
};

export default FiBiAuth;

import React from "react";
import { BoxWrapper, DashboardTitle, DashboardWrapper } from "../styles";
import NewDocument from "./components/NewDocument";
import DocumentsHistory from "./components/DocumentsHistory";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import DocumentChecklist from "./components/DocumentChecklist";

const Documents = () => {
  const [searchParams] = useSearchParams();
  const getType = searchParams.get("form");

  return (
    <DashboardWrapper>
      {getType ? (
        <DocumentChecklist />
      ) : (
        <>
          <DashboardTitle>Documents Review</DashboardTitle>
          <Wrapper>
            <NewDocument />
            <DocumentsHistory />
          </Wrapper>
        </>
      )}
    </DashboardWrapper>
  );
};

export default Documents;

const Wrapper = styled(BoxWrapper)`
  display: flex;
  flex-direction: column;
  gap: 50px;
  min-height: 100%;
  align-items: unset;
  justify-content: unset;
`;

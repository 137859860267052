import React from "react";
import {
  billingData,
  BillingStripeQuery,
  BillingType,
} from "./components/data";
import BillingCard from "./components/BillingCard";
import { useSnapshot } from "valtio";
import { businessUserDataStore } from "../../../../../../store/businessUserData";
import { toast } from "react-toastify";
import {
  useChoosePaymentMode,
  useSubscriptionPayment,
} from "../../../../../../hooks/business/useBilling";
import { ModuleTitle, SpinnerWrapper } from "../../../../components/styles";
import Spinner from "../../../../../../utils/Spinner";
import { useSearchParams } from "react-router-dom";
import BillingSuccessModal from "./components/BillingSuccessModal";
import PerApplicationConfirmation from "./components/PerApplicationConfirmation";
import useOpener from "../../../../../../hooks/useOpener";
import styled from "styled-components";
import AddStaffSeat from "./components/AddStaffSeat";
import { Tooltip } from "@mui/material";

const Billing = () => {
  const { businessUserData } = useSnapshot(businessUserDataStore);
  const [searchParams] = useSearchParams();
  const billingTypeParams = searchParams.get(
    BillingStripeQuery.billingType
  ) as string;
  const paymentTypeParams =
    searchParams.get(BillingStripeQuery.paymentType) || "";
  const { mutate, isLoading } = useChoosePaymentMode();
  const { mutate: submutate, isLoading: subLoading } = useSubscriptionPayment();
  const { onClose, onOpen, open } = useOpener();
  const {
    onClose: seatOnClose,
    onOpen: seatOnOpen,
    open: seatOpen,
  } = useOpener();

  const onSubmit = (params: BillingType) => {
    if (params === BillingType.perApplication) {
      const isSubscriptionMode = false;
      const payload = {
        isSubscriptionMode,
        isPerApplicationMode: !isSubscriptionMode,
      };

      mutate(payload, {
        onSuccess: () => toast.success("Billing Successful"),
      });
    }

    if (params === BillingType.standard || params === BillingType.premium) {
      const payload = {
        billingType: params,
      };

      submutate(payload, {
        onSuccess: (data) => {
          window.location.replace(data.data.url);
        },
      });
    }
  };

  const onClick = (params: BillingType) => {
    params === BillingType.perApplication ? onOpen() : onSubmit(params);
  };

  const masterLoading = isLoading || subLoading;
  const canNotAddSeat =
    businessUserData?.businessInfo?.additionalStaffSeats === 0;

  return (
    <>
      <div className="flex flex-wrap items-center justify-between gap-6 mb-4">
        <Header>Manage Billing</Header>

        <div></div>

        {/* <Tooltip
          title={
            canNotAddSeat
              ? "You’ve reached the maximum limit for adding seats."
              : ""
          }
        >
          <span>
            <Button onClick={seatOnOpen} disabled={canNotAddSeat}>
              Add Staff Seat
            </Button>
          </span>
        </Tooltip> */}
      </div>

      {masterLoading ? (
        <SpinnerWrapper>
          <Spinner size={40} />
        </SpinnerWrapper>
      ) : (
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 ">
          {billingData(!!businessUserData?.paymentInfo.isSubscriptionMode).map(
            (ev) => (
              <BillingCard
                currentPlan={businessUserData?.paymentInfo.subscriptionType}
                seatAdded={
                  businessUserData?.paymentInfo.staff_subscription_info?.length
                }
                data={ev}
                onButtonClick={onClick}
              />
            )
          )}
        </div>
      )}

      {billingTypeParams && paymentTypeParams && <BillingSuccessModal />}
      {open && (
        <PerApplicationConfirmation
          onClose={onClose}
          onSubmit={onSubmit}
          isLoading={masterLoading}
          hasFreeTrail={!!businessUserData?.paymentInfo.isFreeTrialActive}
          hasCurrentPlan={businessUserData?.paymentInfo.subscriptionType}
        />
      )}
      {seatOpen && <AddStaffSeat onClose={seatOnClose} />}
    </>
  );
};

export default Billing;

const Button = styled.button<{ isHighlighted?: boolean }>`
  background-color: ${(props) =>
    !props.isHighlighted ? "#0073ff" : "#f9fafb"};
  color: ${(props) => (props.isHighlighted ? "#6b7280" : "#fff")};
  border: 1px solid ${(props) => (!props.isHighlighted ? "#0073ff" : "#e5e7eb")};
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
  cursor: ${(props) => (props.isHighlighted ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:hover:not(:disabled) {
    opacity: 0.9;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #adadad;
    border: none;
  }
`;

const Header = styled(ModuleTitle)`
  margin-bottom: 0;
`;

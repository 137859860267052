import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import CustomButton from "../../../../../components/CustomButton";
import StaffForm from "./StaffForm";
import RequestWrapper from "../../../../../components/RequestWrapper";
import { ProfileSections } from "../../../types";
import { RoleOptions } from "./data";
import { getSelectedOption } from "../../../../../components/data";
import { FaArrowLeft } from "react-icons/fa6";
import { StaffRes } from "../../../../../../../api/business/staff/types";
import { useGetSingleStaff } from "../../../../../../../hooks/business/useStaff";

const StaffDetails = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const staffId = searchParams.get("staffId");

  const { data, isLoading, isError } = useGetSingleStaff(staffId as string);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <div>
      <Header>
        <div
          role="button"
          onClick={() => navigate(`?active=${ProfileSections.staffManagement}`)}
          className="flex items-center px-4 py-2 text-black duration-150 rounded-lg bg-GreyA7 gap-x-2 hover:bg-Grey8B"
        >
          <FaArrowLeft />
          <span>Back</span>
        </div>
        <CustomButton
          name={"Update Staff"}
          isDisabled={isLoading || isError}
          onClick={() => setModalOpen(true)}
        />
      </Header>

      <StaffForm
        data={data as StaffRes}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />

      <RequestWrapper isError={isError} isLoading={isLoading} data={data}>
        <Wrapper>
          <SectionHeader>
            <div>
              <h3>Staff Information</h3>
              <p>Personal details and role.</p>
            </div>
          </SectionHeader>
          <Divider>
            <DefinitionList>
              <div>
                <dt>Full name</dt>
                <dd>{data?.name}</dd>
              </div>

              <div>
                <dt>Email address</dt>
                <dd>{data?.email}</dd>
              </div>

              <div>
                <dt>Role</dt>
                <dd>{`${
                  getSelectedOption(RoleOptions, data?.role as string)?.label
                }`}</dd>
              </div>

              <div>
                <dt>Job title</dt>
                <dd>{data?.job_title}</dd>
              </div>

              <div>
                <dt>Location</dt>
                <dd>{data?.location}</dd>
              </div>

              {/* <div>
                <dt>Created by</dt>
                <dd>{data?.createdBy}</dd>
              </div> */}

              {/* about the staff */}

              {/* <div> */}
              {/* 	<dt>About</dt> */}
              {/* 	<dd> */}
              {/* 		Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. */}
              {/* 		Excepteur */}
              {/* 		qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure */}
              {/* 		nostrud */}
              {/* 		pariatur mollit ad adipisicing reprehenderit deserunt qui eu. */}
              {/* 	</dd> */}
              {/* </div> */}

              {/* files maybe resume or any related/relevant files */}

              {/* <div> */}
              {/* 	<dt>Attachments</dt> */}
              {/* 	<dd> */}
              {/* 		<AttachmentsList> */}
              {/* 			<AttachmentItem> */}
              {/* 				<AttachmentDetails> */}
              {/* 					<FileIcon aria-hidden="true"/> */}
              {/* 					<div> */}
              {/* 						<FileName>resume_back_end_developer.pdf</FileName> */}
              {/* 						<FileSize>2.4mb</FileSize> */}
              {/* 					</div> */}
              {/* 				</AttachmentDetails> */}
              {/* 				<DownloadLink href="#">Download</DownloadLink> */}
              {/* 			</AttachmentItem> */}
              {/* 			<AttachmentItem> */}
              {/*     	<AttachmentDetails> */}
              {/* 					<FileIcon aria-hidden="true"/> */}
              {/* 					<div> */}
              {/* 						<FileName>coverletter_back_end_developer.pdf</FileName> */}
              {/* 						<FileSize>4.5mb</FileSize> */}
              {/* 					</div> */}
              {/* 				</AttachmentDetails> */}
              {/* 				<DownloadLink href="#">Download</DownloadLink> */}
              {/* 			</AttachmentItem> */}
              {/* 		</AttachmentsList> */}
              {/* 	</dd> */}
              {/* </div> */}
            </DefinitionList>
          </Divider>
        </Wrapper>
      </RequestWrapper>
    </div>
  );
};

export default StaffDetails;

const Wrapper = styled.div`
  padding: 1.24rem 0.5rem;
  margin-top: 1rem;
  border-top: 1px solid #cccccc;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SectionHeader = styled.div`
  padding: 0 1rem;

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1f2937;
  }

  p {
    margin-top: 0.25rem;
    max-width: 40rem;
    font-size: 0.875rem;
    color: #6b7280;
  }
`;

const Divider = styled.div`
  margin-top: 1.5rem;
  border-top: 1px solid #f3f4f6;
`;

const DefinitionList = styled.dl`
  border-collapse: separate;
  border-spacing: 0;

  & > div {
    padding: 1.5rem;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
    border-bottom: 1px solid #f3f4f6;
  }

  dt {
    font-size: 0.875rem;
    font-weight: 500;
    color: #1f2937;
  }

  dd {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    color: #374151;
  }
`;

// const AttachmentsList = styled.ul`
//   border: 1px solid #e5e7eb;
//   border-radius: 0.375rem;
//   list-style: none;
//   padding: 0;
//   margin: 0;
// `;
//
// const AttachmentItem = styled.li`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 1rem;
//   border-bottom: 1px solid #e5e7eb;
//   font-size: 0.875rem;
//   color: #374151;
// `;
//
// const AttachmentDetails = styled.div`
//   display: flex;
//   align-items: center;
// `;
//
// const FileIcon = styled(FaPaperclip)`
//   width: 1.25rem;
//   height: 1.25rem;
//   color: #9ca3af;
// `;
//
// const FileName = styled.span`
//   margin-left: 1rem;
//   flex: 1;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
// `;
//
// const FileSize = styled.span`
//   margin-left: 0.5rem;
//   color: #9ca3af;
// `;
//
// const DownloadLink = styled.a`
//   color: #4f46e5;
//   font-weight: 500;
//
//   &:hover {
//     color: #4338ca;
//   }
// `;

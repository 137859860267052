import TableSection from "../../../../components/table/TableSection";
import { RoleOptions, StaffTableData } from "./components/data";
import { DataItemsProps, QueriesKey } from "../../../../components/type";
import StaffAction from "./components/StaffAction";
import { StaffRes } from "../../../../../../api/business/staff/types";
import { useGetAllStaff } from "../../../../../../hooks/business/useStaff";
import styled from "styled-components";
import { ModuleTitle } from "../../../../components/styles";
import { useState } from "react";
import TableSearch from "../../../../components/table/TableSearch";
import TableDropdown from "../../../../components/table/TableDropdown";
import StaffForm from "./components/StaffForm";
import CreateBtn from "../../CreateBtn";
import useOpener from "../../../../../../hooks/useOpener";
import StaffValModal from "./components/StaffValModal";
import { useSnapshot } from "valtio";
import { businessUserDataStore } from "../../../../../../store/businessUserData";

const StaffManagement = () => {
  const { data, isLoading, isError } = useGetAllStaff();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { open, onClose, onOpen } = useOpener();
  const { businessUserData } = useSnapshot(businessUserDataStore);

  const availableSeat =
    !!businessUserData?.paymentInfo.staff_subscription_info &&
    data &&
    data?.length < businessUserData?.paymentInfo.staff_subscription_info.length;

  const onAddStaff = () => {
    if (!availableSeat) {
      onOpen();
      return;
    }
    setModalOpen(true);
  };

  return (
    <>
      <Wrapper>
        <div className="flex flex-wrap items-center justify-between gap-6">
          <Header>Staff Management</Header>

          <CreateBtn text="Add Staff" onClick={onAddStaff} />
        </div>

        <MiddleWrapper className="my-6">
          <TableSearch placeholder="Search staff" />

          <MiniFlex>
            <TableDropdown
              width="200px"
              name="Role"
              option={RoleOptions}
              queryName={QueriesKey.status}
            />
          </MiniFlex>
        </MiddleWrapper>

        <StaffForm
          isOpen={modalOpen}
          data={null}
          onClose={() => setModalOpen(false)}
        />

        <div className="mt-6 ">
          <TableSection
            dataItems={data}
            tableData={StaffTableData}
            actionComp={(data: DataItemsProps) => (
              <StaffAction data={data as StaffRes} />
            )}
            isError={isError}
            isLoading={isLoading}
          />
        </div>
      </Wrapper>

      {open && <StaffValModal onClose={onClose} />}
    </>
  );
};

export default StaffManagement;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled(ModuleTitle)`
  margin-bottom: 0;
`;

const MiddleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MiniFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

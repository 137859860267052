import { useEffect } from "react";
import styled from "styled-components";
// import CustomButton from "../components/CustomButton";
// import { FaPlus } from "react-icons/fa";
import NewsCard from "../components/NewsCard";
// import { useNavigate } from "react-router-dom";

// import { useGetAllArticle } from "../../hooks/news/useNews";
import RequestWrapper from "../components/RequestWrapper";
import { ModuleTitle } from "../components/styles";
// import { WebPaths } from "../../../routes/data";
import { useGetAllArticle } from "../../../hooks/blogs/useArticles";
import Constaint from "../layout/components/Constaint";
import { mediaObj } from "../../../styles/Media";

const Blogs = () => {
  // const navigate = useNavigate();
  const { data, isLoading, isError } = useGetAllArticle();
  // useEffect(() => {
  //   //
  //   document.title = "SeamlessVisa Blogs";

  //   return (document.title =
  //     "SeamlessVisa | The Trusted Partner in Your Immigration Journey");
  // }, []);

  useEffect(() => {
    document.title = "Blogs | SeamlessVisa";

    return () => {
      document.title =
        "SeamlessVisa | The Trusted Partner in Your Immigration Journey";
    };
  }, []);

  return (
    <Wrapper>
      <Constaint>
        <>
          <Header>
            <ModuleTitle>Articles</ModuleTitle>
            {/* <CustomButton
          name="ADD Article"
          icon={<FaPlus />}
          onClick={() => navigate(WebPaths.NewArticle)}
        /> */}
          </Header>

          <RequestWrapper isLoading={isLoading} isError={isError} data={data}>
            <GridWrapper>
              {data?.map((ev, i) => (
                <NewsCard key={i} showActions data={ev} />
              ))}
            </GridWrapper>
          </RequestWrapper>
        </>
      </Constaint>
    </Wrapper>
  );
};

export default Blogs;

const Wrapper = styled.div`
  padding: 4rem 0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 20px;
  height: 40px;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;

  ${mediaObj.smallDesktop} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${mediaObj.bigMobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

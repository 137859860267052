import { motion } from "framer-motion";
import { FC } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import { DocReviewModalValidation } from "./validation";
import { DocReviewTypeValues } from "./types";
import { useParams } from "react-router-dom";
import { FileDetail } from "../../../../../../../api/n400/types";
import Overlay from "../../../../../checkEligibilty/components/modals/Overlay";
import { ContainerVariants } from "../../../../../checkEligibilty/components/modals/variant";
import InputField from "../../../../../components/formik fields/InputField";
import Spinner from "../../../../../../../utils/Spinner";
import Colors from "../../../../../../../styles/Colors";
import { mediaObj } from "../../../../../../../styles/Media";

interface FormData {
  note: string;
}

interface Props {
  closeAll: () => void;
  onClose: () => void;
  data: FileDetail;
  type: DocReviewTypeValues;
  isAgent?: boolean;
}

const DocReviewModal: FC<Props> = ({
  onClose,
  data,
  type,
  closeAll,
  isAgent,
}) => {
  const { caseId, formCategory, agentId } = useParams();
  const isRecommended = type === DocReviewTypeValues.recommended;
  const isNotRecommended = type === DocReviewTypeValues.notRecommended;
  // const { mutate, isPending } = useRecommendDoc();
  // const { mutate: agentMutate, isPending: agentPending } = useVerifyAgentDoc();
  const isPending = false;
  const agentPending = false;

  const getHeaderText = () => {
    if (isRecommended) {
      return "recommend";
    }

    if (isNotRecommended) {
      return "not recommend";
    }
  };

  const onSuccess = () => {
    toast.success("Document reviewed successfully");
    closeAll();
  };

  // const onSubmit = (values: FormData) => {
  //   const note = values.note;
  //   if (!caseId || !formCategory) return;

  //   const temp = {
  //     docId: caseId,
  //     fileId: data.id,
  //     data: {
  //       category: formCategory,
  //     },
  //   };
  //   if (isRecommended) {
  //     const payload: RecommendDocPayload = {
  //       ...temp,
  //       data: {
  //         ...temp.data,
  //         recommended: true,
  //         not_recommended: false,
  //       },
  //     };

  //     mutate(payload, {
  //       onSuccess,
  //     });
  //   }

  //   if (isNotRecommended && note) {
  //     const payload: RecommendDocPayload = {
  //       ...temp,
  //       data: {
  //         ...temp.data,
  //         recommended: false,
  //         not_recommended: true,
  //         note,
  //       },
  //     };

  //     mutate(payload, {
  //       onSuccess,
  //     });
  //   }
  // };

  // const onAgentSubmit = (values: FormData) => {
  //   const note = values.note;
  //   if (!agentId) return;

  //   const temp = {
  //     docId: data.id,
  //     agentId: agentId,
  //   };
  //   if (isRecommended) {
  //     const payload: VerifyAgentDocPayload = {
  //       ...temp,
  //       data: {
  //         recommended: true,
  //         not_recommended: false,
  //       },
  //     };

  //     agentMutate(payload, {
  //       onSuccess,
  //     });
  //   }

  //   if (isNotRecommended && note) {
  //     const payload: VerifyAgentDocPayload = {
  //       ...temp,
  //       data: {
  //         recommended: false,
  //         not_recommended: true,
  //         note,
  //       },
  //     };

  //     agentMutate(payload, {
  //       onSuccess,
  //     });
  //   }
  // };

  return createPortal(
    <Overlay onClose={onClose}>
      <Wrapper
        onClick={(e) => e.stopPropagation()}
        variants={ContainerVariants}
      >
        <Header>
          Are you sure you want to{" "}
          <ColoredHeader>{getHeaderText()}</ColoredHeader> this document?
        </Header>

        <Para>
          This action will impact the document's status. Please consider
          carefully before proceeding.
        </Para>

        <Formik
          initialValues={
            {
              note: "",
            } as FormData
          }
          validationSchema={isNotRecommended && DocReviewModalValidation}
          onSubmit={() => {}}
          // onSubmit={isAgent ? onAgentSubmit : onSubmit}
        >
          <Form>
            {isNotRecommended && (
              <InputField
                name="note"
                label="Explanation..."
                inputType="text-area"
                placeholder=""
              />
            )}

            <BtnWrapper>
              <CancelBtn onClick={onClose} type="button">
                Cancel
              </CancelBtn>
              <ApproveBtn type="submit">
                {isPending || agentPending ? (
                  <Spinner color={Colors.White} />
                ) : (
                  getHeaderText()
                )}
              </ApproveBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      </Wrapper>
    </Overlay>,
    document.body
  );
};

export default DocReviewModal;

const Header = styled.h2`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.625rem;
  text-align: center;
  color: #1b092c;
`;

const ColoredHeader = styled.span`
  color: ${Colors.Blue00};
  text-transform: capitalize;
`;

const Wrapper = styled(motion.div)`
  width: 50vw;
  height: max-content;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 12px;
  padding: 40px 32px;

  ${mediaObj.smallDesktop} {
    margin: auto;
    width: 60vw;
    padding-left: 5%;
    padding-right: 5%;
  }

  ${mediaObj.tablet} {
    width: 80vw;
  }

  ${mediaObj.bigMobile} {
    width: 90vw;
  }
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  /* justify-content: space-between; */

  ${mediaObj.bigMobile} {
    flex-wrap: wrap;
    gap: 10px;
    & > button {
      flex: 1;
    }
  }
`;

const Btn = styled.button`
  border: none;
  cursor: pointer;
  height: 50px;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  padding: 13px 45px;
  border-radius: 30px;
  white-space: nowrap;
  min-width: 160px;
  text-transform: capitalize;

  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const ApproveBtn = styled(Btn)`
  color: white;
  background: ${Colors.Blue00};
`;

const CancelBtn = styled(Btn)`
  background: #e6e6e6;
  color: #1b092c99;
`;

const Para = styled.p`
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  text-align: center;
  color: rgba(27, 9, 44, 0.9);
  margin-top: 16px;
  margin-bottom: 40px;
`;

import { FC, useEffect, useState } from "react";
import styled from "styled-components";

interface Props {
  progress: boolean;
  caseStatus?: string;
  trueText?: string;
  falseText?: string;
}

const formatStatus = (status: string) => {
  return status
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const StatusTag: FC<Props> = ({
  caseStatus,
  progress,
  trueText,
  falseText,
}) => {
  const [status, setStatus] = useState<string>("");

  useEffect(() => {
    if (caseStatus && caseStatus.trim() !== "") {
      // Format and set the case status if it exists
      setStatus(caseStatus);
    } else {
      // Set status based on progress
      const defaultText = progress
        ? trueText || "submitted"
        : falseText || "ongoing";
      setStatus(defaultText);
    }
  }, [caseStatus, progress, trueText, falseText]);

  return <Status status={status}>{formatStatus(status)}</Status>;
};

export default StatusTag;

interface StatusProps {
  status: string;
}

const statusColors: Record<string, string> = {
  ongoing: "#DA5002", // Bright orange for ongoing (good contrast)
  submitted: "#0276E9", // Blue for submitted (good contrast)

  document_review: "#E68A00", // Darker orange for better contrast
  overall_review: "#D97706", // Darker amber for better contrast
  case_started: "#D88700", // Strong amber color for case started
  appointment_booked: "#6B4423", // Dark brown for better contrast
  application_completed: "#3C8CC9", // Darker blue for better contrast
  visa_waiting: "#C7A600", // Dark mustard yellow for visa waiting
  visa_approved: "#4A8D0A", // Dark green for visa approved (good contrast)
  visa_denied: "#D0021B", // Red for visa denied (good contrast)
};

// Helper function to normalize status input
const getStatusColor = (status: string) => {
  // Normalize status to lowercase and remove spaces
  const normalizedStatus = status.toLowerCase().replace(/\s/g, "_");
  // Return the corresponding color or a default color
  return statusColors[normalizedStatus] || "#000"; // Default to black if no match
};

const Status = styled.span<StatusProps>`
  font-size: 14px;
  color: ${({ status }) => getStatusColor(status)};
  text-transform: capitalize !important;
  font-weight: 600;
`;

import React from "react";
import fibiPopUp from "../../../images/web/fibi-popup.jpeg";
import { useNavigate } from "react-router-dom";
import { WebPaths } from "../../../routes/data";
import { FaTimesCircle } from "react-icons/fa";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const FiBiPopUp: React.FC<Props> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`${
        isOpen
          ? "fixed inset-0 z-10 bg-black/50 backdrop-blur-sm grid place-content-center"
          : "hidden"
      }`}
      onClick={onClose}
    >
      <div className="bg-white p-8 rounded-xl shadow-xl w-[85vw] sm:max-w-[500px] flex flex-col items-center relative">
        <div
          role="button"
          onClick={onClose}
          className="absolute p-3 text-red-400 duration-200 bg-white rounded-full cursor-pointer -top-5 -right-5 hover:text-red-500"
        >
          <FaTimesCircle className="w-5 h-5" />
        </div>
        <img
          src={fibiPopUp}
          alt="fibi pop up modal"
          className="transition-all duration-500 cursor-pointer hover:scale-110"
          onClick={() => {
            navigate(WebPaths.FiBiAi);
            onClose();
          }}
        />
      </div>
    </div>
  );
};

export default FiBiPopUp;

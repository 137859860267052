import React from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import Colors from "../../../../../../styles/Colors";
import { Form, Formik } from "formik";
import { SingleOption } from "../../../../../../types/types";
import { NewDocumentsValidation } from "../../validation";
import SelectField from "../../../../components/formik fields/SelectField";
import { userDataStore } from "../../../../../../store/userData";
import { useSnapshot } from "valtio";
import { getFormatedFormName } from "../../../../components/data";
import useBasedOnFormPaid from "../../../../../../hooks/useBasedOnFormPaid";
import { mediaObj } from "../../../../../../styles/Media";

interface FormProps {
  newDoc: SingleOption | null;
}

const NewDocument = () => {
  const { getBasedOnFormPaid } = useBasedOnFormPaid();
  const { userData } = useSnapshot(userDataStore);
  const [searchParams, setSearchParams] = useSearchParams();

  const startNewDoc = (values: FormProps) => {
    const formType = values.newDoc?.value;
    if (!formType) return;
    searchParams.set("form", formType);
    setSearchParams(searchParams);
  };

  const getPaidDocuments = () => {
    const temp = userData?.caseHistory.filter((ev) =>
      getBasedOnFormPaid(ev.caseType)
    );
    return temp?.map((ev) => ({
      label: getFormatedFormName(ev.caseType).toUpperCase(),
      value: ev.caseType,
    }));
  };

  return (
    <Wrapper>
      <HeadText>Document Upload</HeadText>
      <SubText>
        Please upload the required documents for your application.
      </SubText>
      <Formik
        initialValues={
          {
            newDoc: null,
          } as FormProps
        }
        validationSchema={NewDocumentsValidation}
        onSubmit={startNewDoc}
      >
        <Form className="flex justify-center w-full">
          <FormContents>
            <SelectField
              name="newDoc"
              placeholder="-Select-"
              isRequired
              options={getPaidDocuments()}
              height={"47px"}
            />
            <StartBtn type="submit">continue</StartBtn>
          </FormContents>
        </Form>
      </Formik>
    </Wrapper>
  );
};

export default NewDocument;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const HeadText = styled.h1`
  font-size: 1.2rem;
  font-weight: bold;
`;

const SubText = styled.p`
  font-size: 1.1rem;
  margin-top: 20px;
  margin-bottom: 32px;
  text-align: center;
`;

const FormContents = styled.div`
  width: 100%;
  max-width: 580px;
  height: 47px;
  max-height: 47px;
  display: flex;
  align-items: center;
  gap: 23px;

  ${mediaObj.bigMobile} {
    flex-direction: column;
    align-items: unset;
  }
`;

const StartBtn = styled.button`
  width: max-content;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 41px;
  background-color: ${Colors.Blue00};
  border-radius: 0.5rem;
  transition: 0.3s all ease;
  color: #fff;
  font-weight: 600;

  ${mediaObj.bigMobile} {
    width: 100%;
  }

  &:hover {
    background: #022e58;
  }
`;

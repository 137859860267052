import styled from "styled-components";
import { StatusOptions } from "./components/data";
import { useNavigate, useSearchParams } from "react-router-dom";
import { QueriesKey } from "../../../../components/type";
import TableSearch from "../../../../components/table/TableSearch";
import TableDropdown from "../../../../components/table/TableDropdown";
import TableSection from "../../../../components/table/TableSection";
import { CaseOverviewTableData } from "../dashboard/components/data";
import { ModuleTitle } from "../../../../components/styles";
import CreateApplication from "./components/CreateApplication";
import useOpener from "../../../../../../hooks/useOpener";
import CreateBtn from "../../CreateBtn";
import { useGetBusinessApplications } from "../../../../../../hooks/business/useBusinessApplications";
import {
  InitialPageNo,
  PageLimit,
  perPageOptions,
} from "../../../../components/data";
import TablePagination from "../../../../components/TablePagination";
import { FC, useState } from "react";
import PageDropdown from "../../../../components/table/PageDropdown";
import { ProfileSections } from "../../types";

interface Props {
  canNotStartApplication: boolean;
}

const BusinessCasePage: FC<Props> = ({ canNotStartApplication }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const caseParamsValue = searchParams.get(QueriesKey.caseType);
  const searchParamsValue = searchParams.get(QueriesKey.search);
  const statusParamsValue = searchParams.get(QueriesKey.status);
  const { open, onClose, onOpen } = useOpener();
  const [pageNo, setPageNo] = useState(InitialPageNo);
  const [limitNo, setLimitNo] = useState(PageLimit);
  const { data, isLoading, isError } = useGetBusinessApplications({
    page: searchParamsValue ? InitialPageNo : pageNo,
    limit: limitNo,
    search: searchParamsValue || "",
    casetype: caseParamsValue || "",
    status: statusParamsValue || "",
  });

  const handlePageClick = (params: { selected: number }) => {
    setPageNo(params.selected + 1);
  };

  const handleLimitCLick = (params: string) => {
    setLimitNo(+params);
  };

  const goToBilling = () => {
    navigate(`?active=${ProfileSections.billing}`);
  };

  return (
    <>
      <Wrapper>
        <div className="flex flex-wrap items-center justify-between gap-6">
          <Header>Case Management</Header>

          <CreateBtn
            text="Start a new application"
            onClick={canNotStartApplication ? goToBilling : onOpen}
          />
        </div>
        <MiddleWrapper>
          <TableSearch
            placeholder="Search case number"
            onParamsChange={(passedParams: URLSearchParams) => {
              passedParams.delete(QueriesKey.caseType);
              passedParams.delete(QueriesKey.status);
            }}
          />

          <MiniFlex>
            {/* <TableDropdown
              name="Case Type"
              option={CaseTypeOptions}
              queryName={QueriesKey.caseType}
              onParamsChange={(passedParams: URLSearchParams) => {
                if (searchParamsValue) {
                  passedParams.delete(QueriesKey.search);
                  setSearchParams(passedParams);
                }
              }}
            /> */}
            <TableDropdown
              name="Status"
              option={StatusOptions}
              queryName={QueriesKey.status}
              onParamsChange={(passedParams: URLSearchParams) => {
                if (searchParamsValue) {
                  passedParams.delete(QueriesKey.search);
                  setSearchParams(passedParams);
                }
              }}
            />
          </MiniFlex>
        </MiddleWrapper>

        <TableSection
          dataItems={data?.applicationsData}
          tableData={CaseOverviewTableData()}
          isError={isError}
          isLoading={isLoading}
        />

        {data && data?.applicationsData?.length > 0 && (
          <>
            <TablePagination
              handlePageClick={handlePageClick}
              forcePage={pageNo - 1}
              dataLength={data?.applicationsData.length}
              totalPages={data.dataCount}
              pageLimit={limitNo}
            />

            <div className="flex items-center justify-end mt-2">
              <PageDropdown
                option={perPageOptions}
                onChange={(value) => {
                  handleLimitCLick(value);
                }}
              />
            </div>
          </>
        )}
      </Wrapper>

      {open && <CreateApplication onClose={onClose} />}
    </>
  );
};

export default BusinessCasePage;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled(ModuleTitle)`
  margin-bottom: 0;
`;

const MiddleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;
  margin-top: 40px;
`;

const MiniFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

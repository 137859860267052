import { motion } from "framer-motion";
import React, { FC } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import Overlay from "../../../../../checkEligibilty/components/modals/Overlay";
import { ContainerVariants } from "../../../../../checkEligibilty/components/modals/variant";
import Colors from "../../../../../../../styles/Colors";
import { mediaObj } from "../../../../../../../styles/Media";
import Spinner from "../../../../../../../utils/Spinner";
import { BillingType } from "./data";

interface Props {
  onClose: () => void;
  onSubmit: (params: BillingType) => void;
  isLoading: boolean;
  hasFreeTrail: boolean;
  hasCurrentPlan?: BillingType;
}

const PerApplicationConfirmation: FC<Props> = ({
  onClose,
  onSubmit,
  isLoading,
  hasFreeTrail,
  hasCurrentPlan,
}) => {
  const getContent = () => {
    if (hasFreeTrail) {
      return (
        <div className="flex flex-col gap-2 mt-4 text-base text-center text-gray-800">
          <span>
            You are currently on a free plan. By selecting this plan, your free
            plan will be automatically canceled.
          </span>

          <span>
            Please note that the free plan offered limited features, and this
            upgrade will unlock more advanced options.
          </span>
        </div>
      );
    }

    if (!!hasCurrentPlan) {
      return (
        <div className="flex flex-col gap-2 mt-4 text-base text-center text-gray-800">
          <span>
            You are currently on a {hasCurrentPlan} plan. By selecting this
            plan, your {hasCurrentPlan} plan will be automatically canceled.
          </span>
        </div>
      );
    }
  };

  return createPortal(
    <Overlay onClose={onClose}>
      <Wrapper
        onClick={(e) => e.stopPropagation()}
        variants={ContainerVariants}
      >
        <Header>Confirm Pay Per Application</Header>

        {getContent()}

        <BtnWrapper className="mt-5">
          <CancelBtn onClick={onClose} disabled={isLoading} type="button">
            Cancel
          </CancelBtn>
          <ConfirmBtn
            disabled={isLoading}
            onClick={() => {
              onClose();
              onSubmit(BillingType.perApplication);
            }}
            className="mx-auto"
          >
            {isLoading ? <Spinner color={Colors.White} /> : "Confirm"}
          </ConfirmBtn>
        </BtnWrapper>
      </Wrapper>
    </Overlay>,
    document.body
  );
};

export default PerApplicationConfirmation;

const Wrapper = styled(motion.div)`
  width: 500px;
  height: max-content;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 12px;
  padding: 40px 32px;

  ${mediaObj.smallDesktop} {
    margin: auto;
    width: 60vw;
    padding-left: 5%;
    padding-right: 5%;
  }

  ${mediaObj.tablet} {
    width: 80vw;
  }

  ${mediaObj.bigMobile} {
    width: 90vw;
  }
`;

const Header = styled.h2`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.625rem;
  text-align: center;
  color: #1b092c;
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  ${mediaObj.bigMobile} {
    flex-wrap: wrap;
  }

  & > button {
    flex: 1;
  }
`;

const Btn = styled.button`
  border: none;
  cursor: pointer;
  height: 50px;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  padding: 13px 45px;
  border-radius: 30px;
  white-space: nowrap;
  min-width: 160px;

  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const CancelBtn = styled(Btn)`
  background: #e6e6e6;
  color: #1b092c99;
`;

const ConfirmBtn = styled(Btn)`
  width: 100%;
  background: ${Colors.Blue00};
  color: #ffffff;
`;

import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ProfileSections } from "../../../types";

interface Props {
  isFreeTrialOver: boolean;
}

const FreeTrailOver: FC<Props> = ({ isFreeTrialOver }) => {
  const navigate = useNavigate();

  const onUpgrade = () => {
    navigate(`?active=${ProfileSections.billing}`);
  };

  if (!isFreeTrialOver) {
    return (
      <div className="w-full px-4 py-2 mb-4 text-center text-white rounded-md bg-primaryColor">
        You’re currently enjoying a free trial.{" "}
        <button className="font-medium underline" onClick={onUpgrade}>
          Upgrade
        </button>{" "}
        anytime!
      </div>
    );
  }

  return (
    <div className="w-full px-4 py-2 mb-4 text-center text-white rounded-md bg-primaryColor">
      Your free trial has ended. Please{" "}
      <button className="font-medium underline" onClick={onUpgrade}>
        upgrade
      </button>{" "}
      to continue.
    </div>
  );
};

export default FreeTrailOver;

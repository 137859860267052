import { motion } from "framer-motion";
import React, { FC } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import Overlay from "../../../../../checkEligibilty/components/modals/Overlay";
import { ContainerVariants } from "../../../../../checkEligibilty/components/modals/variant";
import { mediaObj } from "../../../../../../../styles/Media";
import Colors from "../../../../../../../styles/Colors";
import Spinner from "../../../../../../../utils/Spinner";
import { useSubscribeAdditionalStaffSeat } from "../../../../../../../hooks/business/useBilling";

interface Props {
  onClose: () => void;
}

export interface AddStaffSeatFormData {
  noOfSeat: string;
}

const AddStaffSeat: FC<Props> = ({ onClose }) => {
  const { mutate, isLoading } = useSubscribeAdditionalStaffSeat();

  const onAdd = () => {
    const payload = {};

    mutate(payload, {
      onSuccess: (data) => {
        window.location.replace(data.data.url);
      },
    });
  };

  return createPortal(
    <Overlay onClose={onClose}>
      <Wrapper
        onClick={(e) => e.stopPropagation()}
        variants={ContainerVariants}
      >
        <Header>Add Staff Seat</Header>

        <Text>You will be billed $20/month for each seat.</Text>

        <div className="flex flex-col gap-4 mt-5">
          <BtnWrapper>
            <CancelBtn onClick={onClose} disabled={isLoading}>
              Cancel
            </CancelBtn>
            <ConfirmBtn
              disabled={isLoading}
              className="mx-auto"
              onClick={onAdd}
            >
              {isLoading ? <Spinner color={Colors.White} /> : "Add"}
            </ConfirmBtn>
          </BtnWrapper>
        </div>
      </Wrapper>
    </Overlay>,
    document.body
  );
};

export default AddStaffSeat;

const Wrapper = styled(motion.div)`
  width: 437px;
  height: max-content;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 12px;
  padding: 40px 32px;

  ${mediaObj.smallDesktop} {
    margin: auto;
    width: 60vw;
    padding-left: 5%;
    padding-right: 5%;
  }

  ${mediaObj.tablet} {
    width: 80vw;
  }

  ${mediaObj.bigMobile} {
    width: 90vw;
  }
`;

const Header = styled.h2`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.625rem;
  text-align: center;
  color: #1b092c;
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 8px;

  & > button {
    flex: 1;
  }

  ${mediaObj.bigMobile} {
    flex-wrap: wrap-reverse;
    gap: 10px;
  }
`;

const Btn = styled.button`
  border: none;
  cursor: pointer;
  height: 50px;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  padding: 13px 45px;
  border-radius: 30px;
  white-space: nowrap;
  min-width: 160px;

  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const CancelBtn = styled(Btn)`
  background: #e6e6e6;
  color: #1b092c99;
`;

const ConfirmBtn = styled(Btn)`
  width: 100%;
  background: ${Colors.Blue00};
  color: #ffffff;
  transition: all 0.3s ease;
  border: 1px solid ${Colors.Blue00};

  &:hover:not(:disabled) {
    color: ${Colors.Blue00};
    background: #ffffff;
  }
`;

const Text = styled.div`
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 24px;
`;

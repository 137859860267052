import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../../../../../styles/Colors";
import { Form, Formik } from "formik";
import UploadBox from "./upload/UploadBox";
import Spinner from "../../../../../../utils/Spinner";
import DocStatus from "./DocStatus";
import { GetFileDetailObj } from "../../../../../../api/n400/types";
import FileDownload from "./FileDownload";
import { DocChecklistProps } from "./data/types";
import { mediaObj } from "../../../../../../styles/Media";
import { useSearchParams } from "react-router-dom";
import { BusinessQueryKey } from "../../../../../../api/utils/types";

interface Props {
  savedData?: GetFileDetailObj;
  data: DocChecklistProps;
  onSubmit: (
    file: File,
    name: string,
    fileId?: string,
    onSuccessful?: () => void
  ) => void;
  isLoading: boolean;
  isAdditional?: boolean;
}

const DocChecklistItem: FC<Props> = ({
  data,
  onSubmit,
  isLoading,
  savedData,
  isAdditional,
}) => {
  const [searchParams] = useSearchParams();
  const getAppId = searchParams.get(BusinessQueryKey.appId) || "";
  const [isEdit, setIsEdit] = useState(false);
  const [baseData, setBaseData] = useState<GetFileDetailObj>();

  useEffect(() => {
    if (getAppId) {
      let temp: GetFileDetailObj;

      if (!savedData) {
        setBaseData(savedData);
        return;
      }

      if (!savedData.not_recommended && !savedData.recommended) {
        temp = { ...savedData, recommended: true };
        setBaseData(temp);
        return;
      }

      // will not get to the below
      if (savedData.recommended) {
        temp = { ...savedData, recommended: true, not_recommended: false };
        setBaseData(temp);
        return;
      }

      if (savedData.not_recommended) {
        temp = { ...savedData, recommended: false, not_recommended: true };
        setBaseData(temp);
        return;
      }
    } else {
      setBaseData(savedData);
    }
  }, [getAppId, savedData]);

  const onEdit = () => {
    if (!baseData) return;
    const temp = { ...baseData, recommended: false, not_recommended: true };
    setBaseData(temp);
    setIsEdit(true);
  };

  const onCloseEdit = () => {
    if (!baseData) return;
    const temp = { ...baseData, recommended: true, not_recommended: false };
    setBaseData(temp);
    setIsEdit(false);
  };

  return (
    <Container>
      <Circle />
      <Wrapper>
        <MiniFlex>
          <MiniFlexTitle>
            <span className="capitalize ">{data.label}</span>
            {isAdditional && <OpText> {"(additional)"}</OpText>}
            {data.optional && (
              <OpText> {data.optionalText || "(if applicable)"}</OpText>
            )}
            {baseData &&
              (getAppId ? (
                <button
                  className={`px-2 ml-1 text-sm font-bold border rounded-md ${
                    isEdit
                      ? " text-red-500 border-red-400"
                      : "text-primaryColor border-primaryColor"
                  } `}
                  onClick={isEdit ? onCloseEdit : onEdit}
                >
                  {isEdit ? "Close Edit" : "Edit"}
                </button>
              ) : (
                <DocStatus data={baseData} />
              ))}
          </MiniFlexTitle>

          <MiniFlexText>{data.info}</MiniFlexText>
          {baseData && baseData.note && baseData.not_recommended && (
            <InfoWrapper>
              <span>Admin Review:</span> <span>{baseData.note}</span>
            </InfoWrapper>
          )}
        </MiniFlex>
        {baseData && baseData?.recommended && <FileDownload data={baseData} />}
        {(!baseData || baseData?.not_recommended) && !data.isNotFile && (
          <Formik
            initialValues={{
              file: null,
            }}
            onSubmit={(values) => {
              if (!values.file) return;
              onSubmit(
                values.file,
                data.name,
                savedData?.id,
                getAppId ? onCloseEdit : undefined
              );
            }}
          >
            {(formik) => (
              <Form>
                <BtnWrapper>
                  <UploadBox
                    name={"file"}
                    formik={formik}
                    acceptedFormat={"image/*,.pdf"}
                    // maxFileSizeInBytes={OpinionMaxFileSize}
                    btnText="Upload"
                  />

                  {formik.values.file && (
                    <Btn type="submit">
                      {isLoading && <Spinner color={Colors.White} size={14} />}
                      Save
                    </Btn>
                  )}
                </BtnWrapper>
              </Form>
            )}
          </Formik>
        )}
      </Wrapper>
    </Container>
  );
};

export default DocChecklistItem;

const Container = styled.div`
  display: flex;
  gap: 12px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: flex-start;
  gap: 100px;

  ${mediaObj.bigMobile} {
    grid-template-columns: 1fr;
    gap: 12px;
  }
`;

const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${Colors.Blue00};
  flex-shrink: 0;
  margin-top: 4px;
`;

const MiniFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const MiniFlexTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
`;

const MiniFlexText = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: ${Colors.Grey8B};
`;

const OpText = styled.span`
  color: ${Colors.RedC3};
`;

const Btn = styled.button`
  text-transform: uppercase;
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 0.75rem;
  color: ${Colors.White};
  background-color: ${Colors.Blue00};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  ${mediaObj.bigMobile} {
    flex-wrap: wrap;
  }
`;

const InfoWrapper = styled.div`
  font-size: 0.85rem;

  & > span:nth-child(1) {
    color: ${Colors.AmberFE};
    font-weight: bold;
  }
`;

import React, { useEffect, useState } from "react";
import Constaint from "./Constaint";
import { ReactComponent as Logo } from "../../../../images/icons/logo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { WebPaths } from "../../../../routes/data";
import { LiaUserCircle } from "react-icons/lia";
import { navItems } from "./data";
import useIsLoggedIn from "../../../../hooks/useIsLoggedIn";
import {
  HiOutlineLogin,
  HiOutlineLogout,
  HiOutlineMenuAlt3,
  HiX,
} from "react-icons/hi";
import { loginFailed } from "../../../../utils";
import { isBusinessDataStore } from "../../../../store/isBusinessData";
import { useGetUserData } from "../../../../hooks/utils/useUtils";
import { useSnapshot } from "valtio";
import { userDataStore } from "../../../../store/userData";
import { businessUserDataStore } from "../../../../store/businessUserData";
import { useGetBusinessProfile } from "../../../../hooks/business/useAuth";

const NavBar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();
  const [menuOpen, setMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const { isBusinessData } = useSnapshot(isBusinessDataStore);
  const { data } = useGetUserData(!isBusinessData);
  userDataStore.userData = isLoggedIn ? data : undefined;

  const { data: businessData } = useGetBusinessProfile(isBusinessData);
  businessUserDataStore.businessUserData = isLoggedIn
    ? businessData
    : undefined;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`sticky top-0 right-0 z-30 w-full bg-white ${
        isSticky ? "shadow-[0px_2px_8px_1px_#31393C0F]" : ""
      }`}
    >
      {/* <div> */}
      <Constaint>
        <>
          <div className="flex items-center justify-between gap-10 py-4 duration-300 md:py-0 min-h-[72px]">
            <Logo
              onClick={() => navigate(WebPaths.Root)}
              className="cursor-pointer shrink-0"
            />
            {/* Desktop Nav */}
            <div className="items-center justify-between flex-1 hidden gap-16 lg:flex md:gap-4">
              <ul className="flex items-center flex-1 gap-0 justify-evenly text-Black1A">
                {navItems.map((item, i) => (
                  <Link
                    key={i}
                    to={item.link}
                    className="flex flex-wrap items-center gap-2 p-6 text-sm duration-150 grow hover:text-Blue00 hover:bg-BlueEA"
                  >
                    {item.icon}
                    <span>{item.label}</span>
                  </Link>
                ))}
              </ul>
              <div
                onClick={() => {
                  if (isLoggedIn) {
                    if (pathname === WebPaths.Profile) {
                      // User is on the profile page and is logged in, so log them out
                      loginFailed();
                    } else {
                      // User is logged in but not on the profile page, navigate to profile
                      navigate(WebPaths.Profile);
                    }
                  } else {
                    // User is not logged in, navigate to login
                    navigate(WebPaths.Auth);
                  }
                  setMenuOpen(false);
                }}
                className="flex items-center justify-center gap-2 px-8 py-6 text-white duration-150 cursor-pointer bg-Blue00 hover:bg-Blue02"
              >
                {isLoggedIn && pathname === WebPaths.Profile ? (
                  <HiOutlineLogout className="w-6 h-6 rotate-180" />
                ) : isLoggedIn ? (
                  <LiaUserCircle className="w-6 h-6" />
                ) : (
                  <HiOutlineLogin className="w-6 h-6" />
                )}
                <span>
                  {isLoggedIn && pathname === WebPaths.Profile
                    ? "Logout"
                    : isLoggedIn
                    ? "Account"
                    : "Login"}
                </span>
              </div>
            </div>
            {/* Mobile Nav Toggle Button */}
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              className="text-gray-700 lg:hidden focus:outline-none"
            >
              {menuOpen ? (
                <HiX className="w-8 h-8" />
              ) : (
                <HiOutlineMenuAlt3 className="w-8 h-8" />
              )}
            </button>
          </div>

          {/* Mobile Nav */}
          {menuOpen && (
            <div className="duration-300 bg-white border-t border-gray-200 lg:hidden">
              <ul className="flex flex-col gap-4 px-6 py-4 text-Black1A">
                {navItems.map((item, i) => (
                  <Link
                    key={i}
                    to={item.link}
                    className="flex items-center gap-2 p-4 duration-150 hover:text-Blue00"
                    onClick={() => setMenuOpen(false)}
                  >
                    {item.icon}
                    <span>{item.label}</span>
                  </Link>
                ))}
              </ul>
              <div
                onClick={() => {
                  if (isLoggedIn) {
                    if (pathname === WebPaths.Profile) {
                      // User is on the profile page and is logged in, so log them out
                      loginFailed();
                    } else {
                      // User is logged in but not on the profile page, navigate to profile
                      navigate(WebPaths.Profile);
                    }
                  } else {
                    // User is not logged in, navigate to login
                    navigate(WebPaths.Auth);
                  }
                  setMenuOpen(false);
                }}
                className="flex items-center justify-center gap-2 px-8 py-6 text-white duration-150 cursor-pointer bg-Blue00 hover:bg-Blue02"
              >
                {isLoggedIn && pathname === WebPaths.Profile ? (
                  <HiOutlineLogout className="w-6 h-6 rotate-180" />
                ) : isLoggedIn ? (
                  <LiaUserCircle className="w-6 h-6" />
                ) : (
                  <HiOutlineLogin className="w-6 h-6" />
                )}
                <span>
                  {isLoggedIn && pathname === WebPaths.Profile
                    ? "Logout"
                    : isLoggedIn
                    ? "Account"
                    : "Login"}
                </span>
              </div>
            </div>
          )}
        </>
      </Constaint>
    </div>
  );
};

export default NavBar;

import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { tabText } from "./LeftSide";
import { SettingsTabs } from "../../types";
import ProfileSettings from "./ProfileSettings";
import styled from "styled-components";
import Password from "./Password";
import Referral from "./Referral";
import { useSnapshot } from "valtio";
import { isBusinessDataStore } from "../../../../../../store/isBusinessData";
import BusinessProfileSettings from "./BusinessProfileSettings";
import { mediaObj } from "../../../../../../styles/Media";

const RightSide = () => {
  const { isBusinessData } = useSnapshot(isBusinessDataStore);
  const [searchParams, setSearchParams] = useSearchParams();
  const getType = searchParams.get(tabText);

  useEffect(() => {
    if (!getType) {
      searchParams.set(tabText, SettingsTabs.setting);
      setSearchParams(searchParams);
    }
  }, [getType, searchParams, setSearchParams]);

  return (
    <Wrapper>
      {getType === SettingsTabs.setting &&
        (isBusinessData ? <BusinessProfileSettings /> : <ProfileSettings />)}
      {getType === SettingsTabs.password && <Password />}
      {!isBusinessData && getType === SettingsTabs.referral && <Referral />}
    </Wrapper>
  );
};

export default RightSide;

const Wrapper = styled.div`
  flex: 0.8;

  ${mediaObj.bigMobile} {
    flex: 1;
  }
`;

import React, { useState } from "react";
import DocChecklistItem from "../DocChecklistItem";
import { toast } from "react-toastify";
import RequestWrapper from "../../../../../components/RequestWrapper";
import { useSearchParams } from "react-router-dom";
import {
  useGetDS160Eligibility,
  useGetDS160Files,
  useUpdateDS160Files,
  useUploadDS160Files,
} from "../../../../../../../hooks/ds160/usDS160";
import {
  ds160CommonDocChecklist,
  ds160StudentDocChecklist,
  ds160TourismBusinessDocChecklist,
} from "../data/ds160Data";
import AdditionalDocItem from "./AdditionalDocItem";
import AdditionalDoc from "../AdditionalDoc";
import { DS160DocTypeOptions } from "../data/data";
import { DS160PrimaryReasonForVisitingLabels } from "../../../../../checkEligibilty/components/types";
import { BusinessQueryKey } from "../../../../../../../api/utils/types";
import { UpdateDS160FilesPayload } from "../../../../../../../api/ds160/types";

const DS160DocChecklist = () => {
  const [searchParams] = useSearchParams();
  const getApplicationId = searchParams.get(BusinessQueryKey.appId) || "";
  const [loadingField, setLoading] = useState("");
  const getType = searchParams.get("form");
  const {
    data,
    isLoading: isGetting,
    isError,
  } = useGetDS160Files(getApplicationId);
  const {
    data: eligibilityData,
    isLoading: eligibilityLoading,
    isError: eligibilityError,
  } = useGetDS160Eligibility(undefined, getApplicationId);
  const { mutate, isLoading } = useUploadDS160Files();
  const { mutate: updateMutate, isLoading: isUpdating } = useUpdateDS160Files();

  const getDocData = () => {
    if (
      eligibilityData?.primary_reason_for_visiting_us ===
        DS160PrimaryReasonForVisitingLabels["Vacation/Tourism"] ||
      eligibilityData?.primary_reason_for_visiting_us ===
        DS160PrimaryReasonForVisitingLabels["Business trip"]
    ) {
      return ds160CommonDocChecklist.concat(ds160TourismBusinessDocChecklist);
    }

    if (
      eligibilityData?.primary_reason_for_visiting_us ===
      DS160PrimaryReasonForVisitingLabels["To study"]
    ) {
      return ds160CommonDocChecklist.concat(ds160StudentDocChecklist);
    }

    return ds160CommonDocChecklist;
  };

  const docData = getDocData();

  const onSubmit = (
    params: File,
    name: string,
    fileId?: string,
    onSuccessful?: () => void
  ) => {
    const payload = new FormData();
    payload.append("file", params);

    if (fileId && getType) {
      const temp: UpdateDS160FilesPayload = {
        id: fileId,
        formCategory: getType,
        data: payload,
        applicationId: getApplicationId,
      };

      updateMutate(temp, {
        onSuccess: () => {
          onSuccessful && onSuccessful();
          toast.success("Update Successful");
        },
      });
    } else {
      payload.append("name", name);

      const newPayload = {
        data: payload,
        applicationId: getApplicationId,
      };
      mutate(newPayload, {
        onSuccess: () => {
          onSuccessful && onSuccessful();
          toast.success("Upload Successful");
        },
      });
    }
    setLoading(name);
  };

  return (
    <RequestWrapper
      isLoading={isGetting || eligibilityLoading}
      isError={isError || eligibilityError}
      data={!!data || eligibilityData?.primary_reason_for_visiting_us}
    >
      <>
        {docData &&
          docData.map((ev, i) => (
            <DocChecklistItem
              key={i}
              data={ev}
              onSubmit={onSubmit}
              isLoading={loadingField === ev.name && (isLoading || isUpdating)}
              savedData={
                data
                  ? [...data].reverse().find((item) => item.name === ev.name)
                  : undefined
              }
            />
          ))}
        {data && (
          <AdditionalDocItem
            filedetail={data}
            onSubmit={onSubmit}
            loadingField={loadingField}
            loadingState={isLoading || isUpdating}
          />
        )}
        <AdditionalDoc
          docType={DS160DocTypeOptions}
          onSubmit={onSubmit}
          loadingField={loadingField}
          loadingState={isLoading || isUpdating}
        />
      </>
    </RequestWrapper>
  );
};

export default DS160DocChecklist;

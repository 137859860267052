import { useNavigate, useSearchParams } from "react-router-dom";
import { WebPaths } from "../../../../routes/data";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../../../styles/Colors";
import Verified from "./Verified";
import useIsLoggedIn from "../../../../hooks/useIsLoggedIn";
import { Form, Formik, FormikHelpers } from "formik";
import { useSignup, useSignupWithGoogle } from "../../../../hooks/auth/useAuth";
import Spinner from "../../../../utils/Spinner";
// import AuthRightSession from "./AuthRightSession";
import CheckBoxField from "../../components/formik fields/CheckBoxField";
import InputField from "../../components/formik fields/InputField";
import { BusinessSignUpValidation, SignUpValidation } from "./validation";
import EmailNotif from "./EmailNotif";
import PhoneFormatter from "../../components/formik fields/PhoneFormatter";
import SelectField from "../../components/formik fields/SelectField";
import { BusinessTypeData } from "./data";
import { SingleOption } from "../../../../types/types";
// import AuthNav from "./AuthNav";
import { useBusinessSignup } from "../../../../hooks/business/useAuth";
import { SigninBtn } from "./Login";
import { GoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import useLoginRes from "./useLoginRes";
import AuthNav from "./AuthNav";

export enum SignUpType {
  "individual" = "individual",
  "business" = "business",
}

interface FormData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  confirmPassword: string;
  agreement: boolean;
  isAgent: boolean;
}

export interface BusinessSignUpFormData {
  businessType: SingleOption | null;
  businessName: string;
  businessNumber: string;
  businessEmail: string;
  businessAddress: string;
  businessPassword: string;
  businessConfirmPassword: string;
  businessAgreement: boolean;
}

const Join = () => {
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();
  const [openEmailNotif, setEmailNotif] = useState(false);
  // const [refCode, setRefCode] = useState<string | null>("")
  const { mutate, isLoading } = useSignup();
  const { mutate: businessMutate, isLoading: businessLoading } =
    useBusinessSignup();
  const { mutate: googleMutate, isLoading: googleLoading } =
    useSignupWithGoogle();
  const { onLoginSuccess, notVerifiedModal } = useLoginRes();

  useEffect(() => {
    if (isLoggedIn) navigate(WebPaths.Profile);
  }, [isLoggedIn, navigate]);

  // Account verification
  const [searchParams] = useSearchParams();
  const getType = searchParams.get("type") as SignUpType;

  const [isVerified, setIsVerified] = useState<string | boolean | null>(
    searchParams.get("verfication")
  );

  const ref = searchParams.get("ref");
  const [userID] = useState<string | null>(searchParams.get("user"));

  const onCloseModal = () => {
    setIsVerified(false);
  };

  const onSubmit = (
    values: FormData,
    { resetForm }: FormikHelpers<FormData>
  ) => {
    const { firstName, lastName, email, password, phoneNumber, isAgent } =
      values;
    const payload = {
      name: firstName + " " + lastName,
      phoneNumber: phoneNumber,
      email: email,
      password: password,
      isAgent,
    };

    mutate(
      { data: payload, refCode: ref },
      {
        onSuccess: () => {
          setEmailNotif(true);
          resetForm();
        },
      }
    );
  };

  const businessOnSubmit = (
    values: BusinessSignUpFormData,
    { resetForm }: FormikHelpers<BusinessSignUpFormData>
  ) => {
    const {
      businessConfirmPassword,
      businessAgreement,
      businessType,
      ...rest
    } = values;

    if (!businessType) return;

    const payload = {
      ...rest,
      businessType: businessType.value,
    };

    businessMutate(payload, {
      onSuccess: () => {
        setEmailNotif(true);
        resetForm();
      },
    });
  };

  useEffect(() => {
    if (getType !== SignUpType.individual && getType !== SignUpType.business) {
      navigate(`?type=${SignUpType.individual}`);
    }
  }, [getType, navigate]);

  const isBusinessType = getType === SignUpType.business;

  const masterLoading = isLoading || googleLoading;

  return (
    <div className="flex items-center justify-center">
      <div className="flex mx-auto">
        <div className="flex-1 w-full max-w-3xl p-5 mx-auto sm:p-16">
          <h2 className="mb-8 text-xl font-semibold text-center">
            Create Account
          </h2>
          <AuthNav />
          {!isBusinessType && (
            <Formik
              initialValues={
                {
                  firstName: "",
                  lastName: "",
                  phoneNumber: "",
                  email: "",
                  password: "",
                  confirmPassword: "",
                  agreement: false,
                  isAgent: false,
                } as FormData
              }
              validationSchema={SignUpValidation}
              onSubmit={onSubmit}
            >
              <Former>
                <div className="flex flex-col items-start gap-6 sm:flex-row">
                  <InputField
                    name="firstName"
                    label="First name"
                    placeholder=""
                  />
                  <InputField
                    name="lastName"
                    label="Last name"
                    placeholder=""
                  />
                </div>
                <PhoneFormatter name="phoneNumber" label="Phone Number" />

                <InputField name="email" label="Email" placeholder="" />

                <InputField
                  name="password"
                  label="Password"
                  placeholder=""
                  inputType="password"
                />

                <InputField
                  name="confirmPassword"
                  label="Confirm Password"
                  placeholder=""
                  inputType="password"
                />

                {/* <CheckBoxField
                label={
                  <p>Check this box if you are signing up as a Business </p>
                }
                name="isAgent"
              /> */}

                <CheckBoxField
                  label={
                    <>
                      By checking this box, you confirm that you've read and
                      accepted our{" "}
                      <a
                        href="https://app.termly.io/document/terms-of-service/52cfeaf3-bc98-4aee-8e68-ae9526870a50"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[#0076E9]"
                      >
                        Terms of Use
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://app.termly.io/document/privacy-policy/22fda0c1-0085-4dac-aabd-11a41a97895a"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[#0076E9]"
                      >
                        Privacy Policy.
                      </a>
                    </>
                  }
                  name="agreement"
                />

                <SigninBtn disabled={masterLoading}>
                  {masterLoading && <Spinner color={Colors.White} size={14} />}
                  Get Started
                </SigninBtn>
              </Former>
            </Formik>
          )}

          {isBusinessType && (
            <Formik
              initialValues={
                {
                  businessType: null,
                  businessName: "",
                  businessNumber: "",
                  businessEmail: "",
                  businessAddress: "",
                  businessPassword: "",
                  businessConfirmPassword: "",
                  businessAgreement: false,
                } as BusinessSignUpFormData
              }
              validationSchema={BusinessSignUpValidation}
              onSubmit={businessOnSubmit}
            >
              {(formik) => (
                <Former>
                  <SelectField
                    name="businessType"
                    label="What type of business do you own?"
                    options={BusinessTypeData}
                  />
                  <InputField
                    name="businessName"
                    label="Business Name"
                    placeholder=""
                  />

                  <PhoneFormatter
                    name="businessNumber"
                    label="Business Number"
                  />

                  <InputField
                    name="businessEmail"
                    label="Business Email"
                    placeholder=""
                  />

                  <InputField
                    name="businessAddress"
                    label="Business Address"
                    placeholder=""
                  />

                  <InputField
                    name="businessPassword"
                    label="Password"
                    placeholder=""
                    inputType="password"
                  />

                  <InputField
                    name="businessConfirmPassword"
                    label="Confirm Password"
                    placeholder=""
                    inputType="password"
                  />

                  {/* <CheckBoxField
                label={
                  <p>Check this box if you are signing up as a Business </p>
                }
                name="isAgent"
              /> */}

                  <CheckBoxField
                    label={
                      <>
                        By checking this box, you confirm that you've read and
                        accepted our{" "}
                        <a
                          href="https://app.termly.io/document/terms-of-service/52cfeaf3-bc98-4aee-8e68-ae9526870a50"
                          target="_blank"
                          rel="noreferrer"
                          className="text-[#0076E9]"
                        >
                          Terms of Use
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://app.termly.io/document/privacy-policy/22fda0c1-0085-4dac-aabd-11a41a97895a"
                          target="_blank"
                          rel="noreferrer"
                          className="text-[#0076E9]"
                        >
                          Privacy Policy.
                        </a>
                      </>
                    }
                    name="businessAgreement"
                  />

                  <SigninBtn disabled={businessLoading}>
                    {businessLoading && (
                      <Spinner color={Colors.White} size={14} />
                    )}
                    Sign Up
                  </SigninBtn>
                </Former>
              )}
            </Formik>
          )}

          {!isBusinessType && (
            <>
              <div className="flex items-center justify-center my-2">or</div>

              <div className="flex items-center justify-center ">
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    if (!credentialResponse.credential) return;
                    googleMutate(
                      { token: credentialResponse.credential },
                      {
                        onSuccess: onLoginSuccess,
                      }
                    );
                  }}
                  onError={() => {
                    toast.error("Login Failed");
                  }}
                />
              </div>
            </>
          )}

          <p className="my-5 text-center">
            Already have {isBusinessType ? "a" : "an"} {getType} account?{" "}
            <button
              onClick={() =>
                navigate(
                  `${WebPaths.Auth}${
                    isBusinessType ? `?type=${SignUpType.business}` : ""
                  }`
                )
              }
              className="text-[#0076E9]"
              type="submit"
            >
              Log In
            </button>
          </p>
        </div>
        {/* <AuthRightSession /> */}
        {notVerifiedModal}
      </div>
      {isVerified && <Verified onClose={onCloseModal} userID={userID} />}
      {openEmailNotif && (
        <EmailNotif
          onClose={() => setEmailNotif(false)}
          isBusinessType={isBusinessType}
        />
      )}
    </div>
  );
};
export default Join;

const Former = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

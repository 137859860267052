import React, { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { userDataStore } from "../../../../store/userData";
import { FaCircleNotch, FaMicrophone, FaPlay, FaStop } from "react-icons/fa6";

import SoundWave from "./SoundWave";
import { Message, MessageSender } from "./FiBiVoice";
import fibiImg from "../../../../images/web/fibi.png";
import userImg from "../../../../images/web/user.png";

interface ConversationProps {
  isSpeaking: boolean;
  isPlaying: boolean;
  chatHistory: Message[];
  startMic: () => void;
  stopMic: () => void;
  startSpeak: (text: string) => void;
  stopSpeak: () => void;
  loadingAudio: boolean;
  transcript: string;
}

export const isUserMessage = (message: Message): boolean => {
  return message.sender === MessageSender.user;
};

const Conversation: React.FC<ConversationProps> = ({
  isPlaying,
  isSpeaking,
  chatHistory,
  startMic,
  stopMic,
  startSpeak,
  stopSpeak,
  loadingAudio,
  transcript,
}) => {
  const { userData } = useSnapshot(userDataStore);

  const [lastUserMessage, setLastUserMessage] = useState<string | null>(null);
  const [lastBotMessage, setLastBotMessage] = useState<string | null>(null);

  useEffect(() => {
    if (chatHistory.length > 0) {
      // Get the last user message
      const lastUser = chatHistory
        .slice()
        .reverse()
        .find((msg) => msg.sender === MessageSender.user);

      // Get the last bot message
      const lastBot = chatHistory
        .slice()
        .reverse()
        .find((msg) => msg.sender === MessageSender.bot);

      // Update states
      if (lastUser) {
        setLastUserMessage(lastUser.message);
      }

      if (lastBot) {
        setLastBotMessage(lastBot.message);
      }
    }
  }, [chatHistory]); // Run this whenever chatHistory changes

  const onSpeak = () => {
    startSpeak(lastBotMessage || "");
  };

  return (
    <div className="flex flex-col items-center justify-start p-6 border rounded-2xl gap-y-4">
      <h1 className="text-center text-[40px] text-Blue00 font-bold">
        FiBi Interview with{" "}
        <span className="bg-gradient-to-r from-Blue00 via-[#4A7CB1] to-[#9D80D6] bg-clip-text text-transparent">
          {userData?.userInfo?.name.split(" ")[0]}
        </span>
      </h1>
      <div className=" w-full lg:w-[80%]">
        <div className="flex flex-col items-start justify-center flex-grow gap-4 mt-10 sm:flex-row">
          {/* FIBI */}
          <div className="flex-1 w-full">
            <div
              className={`relative border rounded-2xl p-6 md:py-10 md:px-20 flex items-start justify-center gap-x-6 bg-[#E8F4FF]`}
            >
              {/* the microphone icon */}
              <div
                role="button"
                onClick={isPlaying ? stopSpeak : onSpeak}
                className={`absolute bottom-2 left-2 h-[48px] w-[48px] rounded-full border duration-150 ${
                  isPlaying
                    ? " border-red-200 text-red-500 bg-red-50 hover:bg-red-100 "
                    : " border-Blue00/30 text-Blue00 bg-Blue00/10 hover:bg-Blue00/20 "
                } flex items-center justify-center p-4 `}
              >
                {loadingAudio ? (
                  <FaCircleNotch size={18} className="animate-spin" />
                ) : isPlaying ? (
                  <FaStop size={18} />
                ) : (
                  <FaPlay size={18} />
                )}
              </div>

              <div className="flex flex-col items-center justify-center">
                <span className="mb-4 font-bold text-Blue00 text-">FiBi</span>

                <img
                  src={fibiImg}
                  alt="fibi"
                  className={`${isPlaying ? "" : ""}`}
                />

                <div className="">
                  <SoundWave isPlaying={isPlaying} />
                </div>
              </div>
            </div>

            <div className="border rounded-3xl rounded-tl-md p-6 py-4 mt-5 max-h-[350px]">
              {lastBotMessage}
            </div>
          </div>

          {/* USER */}
          <div className="flex-1 w-full">
            <div
              className={`relative border rounded-2xl p-6 md:py-10 md:px-20 flex items-start justify-center gap-x-6 bg-[#E8F4FF]`}
            >
              {/* the microphone icon */}
              <div
                role="button"
                onClick={isSpeaking ? stopMic : startMic}
                className={`absolute bottom-2 left-2 h-[48px] w-[48px] rounded-full border border-red-200 text-red-500 bg-red-50 hover:bg-red-100 flex items-center justify-center p-4 duration-150`}
              >
                {isSpeaking ? <FaStop size={18} /> : <FaMicrophone size={18} />}
              </div>

              {/* the user */}
              <div className="flex flex-col items-center justify-center">
                <span className="mb-4 font-bold text-center text-Blue00 text-">
                  {userData?.userInfo?.name.split(" ")[0]}
                </span>

                <img
                  src={userImg}
                  alt="fibi"
                  className={`${isSpeaking ? "" : ""} duration-150`}
                />

                <div className="">
                  <SoundWave isPlaying={isSpeaking} />
                </div>
              </div>
            </div>

            <div className="border rounded-3xl rounded-tr-md p-6 py-4 mt-5 max-h-[350px]">
              {transcript.trim() !== "" || isSpeaking
                ? transcript
                : lastUserMessage}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Conversation;

import { FC, useState } from "react";
import styled from "styled-components";
import { IoEyeOutline } from "react-icons/io5";
import DocViewModal from "./DocViewModal";
import { FiDownload } from "react-icons/fi";
import { FileDetail } from "../../../../../../../api/n400/types";
import Colors from "../../../../../../../styles/Colors";

interface Props {
  data?: FileDetail[];
  isAgent?: boolean;
}

const DocumentsTable: FC<Props> = ({ data, isAgent }) => {
  const [open, setOpen] = useState<FileDetail>();

  const onOpen = (params: FileDetail) => {
    setOpen(params);
  };

  const onClose = () => {
    setOpen(undefined);
  };
  return (
    <>
      <>
        {data?.length === 0 ? (
          <div>
            <NoData>No document review history yet.</NoData>
          </div>
        ) : (
          <div className="flex flex-col gap-6 ">
            {data?.map((ev, i) => {
              return (
                <div
                  key={i}
                  className="flex items-center justify-between gap-6 "
                >
                  <Texter>
                    <Circle />
                    {ev.name}
                  </Texter>

                  <BtnWrapper>
                    <ViewBtn onClick={() => onOpen(ev)}>
                      View
                      <IoEyeOutline />
                    </ViewBtn>

                    <a href={ev.url} download>
                      <DownloadBtn>
                        download
                        <FiDownload />
                      </DownloadBtn>
                    </a>
                  </BtnWrapper>
                </div>
              );
            })}
          </div>
        )}
      </>

      {open && <DocViewModal onClose={onClose} data={open} isAgent={isAgent} />}
    </>
  );
};

export default DocumentsTable;

const Texter = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const NoData = styled.div`
  text-transform: capitalize;
  padding: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Circle = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${Colors.Blue00};
  border-radius: 100%;
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const Btn = styled.button`
  padding: 6px 16px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  text-transform: capitalize;
  font-size: 0.875rem;
`;

const ViewBtn = styled(Btn)`
  border: 1px solid ${Colors.Blue00};
  color: ${Colors.Blue00};

  svg path {
    color: ${Colors.Blue00};
  }
`;

const DownloadBtn = styled(Btn)`
  border: 1px solid ${Colors.Black31};
  color: ${Colors.Black31};
`;

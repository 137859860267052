import styled from "styled-components";
import { UsersTableData } from "./components/data";
import { useSearchParams } from "react-router-dom";
import { QueriesKey } from "../../../../components/type";
import TableSearch from "../../../../components/table/TableSearch";
// import { CSVLink } from "react-csv";
import TableSection from "../../../../components/table/TableSection";
import { ModuleTitle } from "../../../../components/styles";
import Colors from "../../../../../../styles/Colors";
import useOpener from "../../../../../../hooks/useOpener";
import CreateUser from "./components/CreateUser";
import CreateBtn from "../../CreateBtn";
import { useGetBusinessUsers } from "../../../../../../hooks/business/useBusinessUsers";
import {
  InitialPageNo,
  PageLimit,
  perPageOptions,
} from "../../../../components/data";
import PageDropdown from "../../../../components/table/PageDropdown";
import { useState } from "react";
import TablePagination from "../../../../components/TablePagination";

const BusinessUsersPage = () => {
  const [searchParams] = useSearchParams();
  const searchParamsValue = searchParams.get(QueriesKey.search);
  const { open, onClose, onOpen } = useOpener();
  const [pageNo, setPageNo] = useState(InitialPageNo);
  const [limitNo, setLimitNo] = useState(PageLimit);

  const { data, isLoading, isError } = useGetBusinessUsers({
    page: searchParamsValue ? InitialPageNo : pageNo,
    limit: limitNo,
    email: searchParamsValue || "",
  });

  const handlePageClick = (params: { selected: number }) => {
    setPageNo(params.selected + 1);
  };

  const handleLimitCLick = (params: string) => {
    setLimitNo(+params);
  };

  return (
    <>
      <Wrapper>
        <div className="flex flex-wrap items-center justify-between gap-6">
          <Header>
            Clients Management{" "}
            <Colored>({data?.dataCount.toLocaleString() || "--"})</Colored>
          </Header>

          <CreateBtn text="Create Client" onClick={onOpen} />
        </div>

        <Flexxer className="my-6">
          <TableSearch placeholder="Search by email..." />
          {/* {data?.users && (
            <CSVLink data={data?.users}>
              <Btn>Export data</Btn>
            </CSVLink>
          )} */}
        </Flexxer>
        <TableSection
          dataItems={data?.usersData}
          tableData={UsersTableData}
          isError={isError}
          isLoading={isLoading}
        />

        {data && data.dataCount > 0 && (
          <>
            <TablePagination
              handlePageClick={handlePageClick}
              forcePage={pageNo - 1}
              dataLength={data.dataCount}
              totalPages={data.totalPageCount}
              pageLimit={limitNo}
            />

            <div className="flex items-center justify-end mt-2">
              <PageDropdown
                option={perPageOptions}
                onChange={(value) => {
                  handleLimitCLick(value);
                }}
              />
            </div>
          </>
        )}
      </Wrapper>

      {open && <CreateUser onClose={onClose} />}
    </>
  );
};

export default BusinessUsersPage;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled(ModuleTitle)`
  margin-bottom: 0;
`;

const Colored = styled.span`
  color: ${Colors.Blue00};
`;

const Flexxer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const recurringBillingData = [
  {
    name: "Standard",
    id: "tier-standard",
    href: "#",
    priceMonthly: "$99",
    description: "Essential tools for your AI needs.",
    features: ["API Integration", "AI Risk Checker", "AI Interview Trainer"],
    featured: false,
  },
  {
    name: "Premium",
    id: "tier-premium",
    href: "#",
    priceMonthly: "$199",
    description: "Advanced AI features with collaboration tools.",
    features: [
      "API Integration",
      "AI Risk Checker",
      "AI Interview Trainer",
      "Case Evaluation",
      "Client Collaboration",
      "Customized Dashboard",
    ],
    featured: true, // Highlight the premium plan
  },
  //   {
  //     name: "Enterprise",
  //     id: "tier-enterprise",
  //     href: "#",
  //     priceMonthly: "$99",
  //     description: "Tailored solutions for your business needs.",
  //     features: ["Get tailored solutions for your business needs"],
  //     featured: false,
  //   },
];

export enum BillingType {
  perApplication = "payPerApplication",
  standard = "standard",
  premium = "premium",
  enterprise = "enterprise",
  staff = "staff",
}

export interface BillingDataProps {
  id: BillingType;
  title: string;
  price: string;
  pricePeriod?: string;
  features: (string | JSX.Element)[];
  isCustom?: boolean;
  buttonText: string;
}

export const billingData = (
  onceHadASubscriptionPlan: boolean
): BillingDataProps[] => {
  const temp = [
    {
      id: BillingType.standard,
      title: "Standard",
      price: "$99",
      pricePeriod: "month",
      features: [
        "AI Risk Checker",
        "AI Interview Trainer",
        "Client Management",
        "Case Management",
        "Staff Management",
        "Technical Support",
        "1 Staff Seat (can add up to 2 seats for $20/month, per seat)",
      ],
      buttonText: "Select Standard",
    },
    {
      id: BillingType.premium,
      title: "Premium",
      price: "$199",
      pricePeriod: "month",
      features: [
        <>
          Everything in <strong className=" text-primaryColor">Standard</strong>{" "}
          Plan
        </>,
        "Early access to new features",
        "Case Tracking",
        "3 staff seats (up to 2 additional seats available for $20/month, per seat)",
      ],
      buttonText: "Select Premium",
    },
    {
      id: BillingType.enterprise,
      title: "Enterprise",
      price: "Custom Pricing",
      pricePeriod: "",
      features: [
        <>
          Everything in <strong className=" text-primaryColor">Premium</strong>{" "}
          Plan
        </>,
        "API integrations",
        "Get tailored solutions for your business needs",
      ],
      buttonText: "Contact Us",
      isCustom: true,
    },
  ];

  if (!onceHadASubscriptionPlan) {
    temp.unshift({
      id: BillingType.perApplication,
      title: "Per Application",
      price: "$49",
      pricePeriod: "application",
      features: [
        "AI Risk Checker",
        "AI Interview Trainer",
        "Client Management",
        "Case Management",
        "Staff Management",
        "Technical Support",
        "1 Staff Seat",
      ],
      buttonText: "Select Per Application",
    });
  }

  return temp;
};

export enum BillingStripeQuery {
  billingType = "billingType",
  paymentType = "paymentType",
  session_id = "session_id",
}

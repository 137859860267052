import { FC, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

interface Props {
  url: string;
}

const LoadPdf: FC<Props> = ({ url }) => {
  const [iframeTimeoutId, setIframeTimeoutId] = useState<NodeJS.Timeout>();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const getIframeLink = useCallback(() => {
    return `https://docs.google.com/gview?url=${url}&embedded=true`;
  }, [url]);

  const updateIframeSrc = useCallback(() => {
    if (iframeRef.current) {
      iframeRef.current.src = getIframeLink();
    }
  }, [getIframeLink]);

  useEffect(() => {
    const intervalId = setInterval(updateIframeSrc, 1000 * 3);
    setIframeTimeoutId(intervalId);
  }, [updateIframeSrc]);

  function iframeLoaded() {
    clearInterval(iframeTimeoutId);
  }

  return (
    <IFrame
      onLoad={iframeLoaded}
      onError={updateIframeSrc}
      ref={iframeRef}
      src={getIframeLink()}
    />
  );
};

export default LoadPdf;

const IFrame = styled.iframe`
  width: 100%;
  height: 200%;
`;

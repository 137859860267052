// import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {
  indHeroFour,
  indHeroOne,
  indHeroThree,
  indHeroTwo,
} from "../../../../../images";
import { motion } from "framer-motion";
import {
  HeroImagesVariant1,
  HeroImagesVariant2,
  LeftHeroVariant,
  RightHeroVariant,
} from "../../../landing page/components/variants";
import Constaint from "../../../layout/components/Constaint";
import Colors from "../../../../../styles/Colors";

const Hero = () => {
  // const navigate = useNavigate();

  return (
    <Wrapper>
      <Constaint>
        <Container>
          <Section>
            <LeftSection
              variants={LeftHeroVariant}
              animate={"animate"}
              initial="initial"
            >
              <H1>Empower Your Dreams, Get Your Visa Approved</H1>
              <Para>
                We combine technology with expert support to maximize your U.S.
                visa approval chances. Our advanced error-checking system
                ensures your application is accurate. With our AI interview
                trainer, receive expert feedback and develop the confidence you
                need to impress immigration officers.
              </Para>
              {/* <CTA onClick={() => navigate(WebPaths.Services)}>Get Started</CTA> */}
            </LeftSection>
            <RightSection
              variants={RightHeroVariant}
              animate={"animate"}
              initial="initial"
            >
              <Flexer>
                <MiniFlex>
                  <ImageBox
                    src={indHeroOne}
                    height={"380px"}
                    variants={HeroImagesVariant1}
                    animate={"animate"}
                  />
                  <ImageBox
                    src={indHeroTwo}
                    height={"170px"}
                    variants={HeroImagesVariant2}
                    animate={"animate"}
                  />
                </MiniFlex>
                <MiniFlex>
                  <ImageBox
                    src={indHeroThree}
                    height={"170px"}
                    variants={HeroImagesVariant2}
                    animate={"animate"}
                  />
                  <ImageBox
                    src={indHeroFour}
                    height={"380px"}
                    variants={HeroImagesVariant1}
                    animate={"animate"}
                  />
                </MiniFlex>
              </Flexer>
            </RightSection>
          </Section>
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default Hero;

const Wrapper = styled.div`
  background-color: #c6d9ec73;
`;

const Container = styled.div`
  padding-top: 32px;
  padding-bottom: 98px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 89px;
  overflow-x: hidden;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;
const LeftSection = styled(motion.div)`
  width: 100%;
  text-align: center;

  @media only screen and (min-width: 768px) {
    width: 45%;
    text-align: left;
  }
`;

const H1 = styled.h1`
  font-size: 2.5rem;
  font-family: "League Spartan", sans-serif;
  font-weight: 700;
  line-height: 3.8125rem;
  letter-spacing: 0em;
  color: ${Colors.Blue00};

  /* & > span {
    background: linear-gradient(
      82.05deg,
      #0076e9 4.03%,
      #477caf 32.89%,
      #a882db 92.08%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: "League Spartan", sans-serif;
  } */

  @media only screen and (min-width: 768px) {
    font-size: 4rem;
    line-height: 4.8125rem;
  }
`;

const Para = styled.p`
  margin: 40px auto;

  @media only screen and (min-width: 768px) {
    margin: 40px 0;
  }

  width: 90%;
  font-size: 1.25rem;
  font-weight: 400;
  /* line-height: 1.4375rem; */
  letter-spacing: 0em;
`;

// const CTA = styled.button`
//   width: 222px;
//   height: 56px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 18px 59px;
//   font-size: 1rem;
//   font-weight: 600;
//   line-height: 1.1875rem;
//   letter-spacing: 0em;
//   background: #0076e9;
//   border-radius: 8px;
//   color: #f9fcff;

//   &:hover {
//     background: #022e58;
//     color: #f9fcff;
//     transition: 0.3s ease-in-out;
//   }

//   margin: auto;

//   @media only screen and (min-width: 768px) {
//     margin: 40px 0;
//   }
// `;

const RightSection = styled(motion.div)`
  width: 100%;

  @media only screen and (min-width: 768px) {
    width: 51%;
  }
`;

interface ImageBoxProps {
  height: string;
}

const ImageBox = styled(motion.img)<ImageBoxProps>`
  height: ${({ height }) => height};
  border-radius: 16px;
  object-fit: cover;
`;

const Flexer = styled.div`
  display: flex;
  gap: 20px;
  height: 100%;
`;

const MiniFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
`;

import { Link } from "react-router-dom";
import fibiScreenshot from "../../../../images/web/fibi-screenshot.png";
import { AiOutlineComment } from "react-icons/ai";
import { WebPaths } from "../../../../routes/data";

const FiBiSection = () => {
  return (
    <div className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-base/7 font-semibold text-blue-400">FiBi AI</h2>
          <p className="mt-2 text-pretty text-4xl font-bold font-spartan tracking-tight text-white sm:text-balance sm:text-5xl">
            Meet FiBi AI - Your Personal Interview Trainer
          </p>
          <p className="mt-6 text-lg/8 text-gray-300">
            Practice real U.S. visa interview questions with both text and voice
            simulations to boost your confidence and approval chances.
          </p>
        </div>
      </div>
      <div className="relative overflow-hidden pt-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <img
            alt="App screenshot"
            src={fibiScreenshot}
            width={2432}
            height={1442}
            className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-white/10"
          />
          <div aria-hidden="true" className="relative">
            <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-gray-900 pt-[7%]" />
          </div>
        </div>
      </div>

      <Link
        to={WebPaths.FiBiAi}
        className="group bg-[#EAC5FC] grow text-sm font-semibold text-Black shadow-sm w-fit mt-10 mx-auto flex items-center gap-x-16 justify-between pl-5"
      >
        <span className="">Try FiBi AI interview</span>
        <div className="bg-[#A881DB] group-hover:bg-[#A881DB]/40 p-4 text-white duration-150">
          <AiOutlineComment className="h-6 w-6" />
        </div>
      </Link>
      {/* <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
        <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base/7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name} className="relative pl-9">
              <dt className="inline font-semibold text-white">
                {feature.icon}
                {feature.name}
              </dt>{" "}
              <dd className="inline">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div> */}
    </div>
  );
};

export default FiBiSection;

import React from "react";
import { useNavigate } from "react-router-dom";
import { ProfileSections } from "../../../types";

const NeedToSubBanner = () => {
  const navigate = useNavigate();

  const onUpgrade = () => {
    navigate(`?active=${ProfileSections.billing}`);
  };

  return (
    <div className="w-full px-4 py-2 mb-4 text-center text-white rounded-md bg-primaryColor">
      Your subscription is no longer active. Please{" "}
      <button className="font-medium underline" onClick={onUpgrade}>
        subscribe
      </button>{" "}
      to continue.
    </div>
  );
};

export default NeedToSubBanner;

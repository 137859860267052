import React, { FC } from "react";
import styled from "styled-components";
import Colors from "../../../../../../../styles/Colors";
import useOpener from "../../../../../../../hooks/useOpener";
import CreateUser from "../../users/components/CreateUser";
import CreateApplication from "../../case/components/CreateApplication";
import { GetSingleBusinessUsersRes } from "../../../../../../../api/business/users/types";
import { useNavigate } from "react-router-dom";
import { ProfileSections } from "../../../types";

interface Props {
  data: GetSingleBusinessUsersRes;
  canNotStartApplication: boolean;
}

const SingleUserActions: FC<Props> = ({ data, canNotStartApplication }) => {
  const navigate = useNavigate();
  const {
    open: downloadOpen,
    onClose: downloadClose,
    onOpen: onDownloadOpen,
  } = useOpener();
  const {
    open: editOpen,
    onClose: onEditClose,
    onOpen: onEditOpen,
  } = useOpener();

  const goToBilling = () => {
    navigate(`?active=${ProfileSections.billing}`);
  };

  return (
    <>
      <Wrapper>
        <H5>Action</H5>
        <MiniWrapper>
          <EditUserBtn onClick={onEditOpen}>Edit Client</EditUserBtn>
          <RiskBtn
            onClick={canNotStartApplication ? goToBilling : onDownloadOpen}
          >
            Start A New Application
          </RiskBtn>
        </MiniWrapper>
      </Wrapper>
      {downloadOpen && (
        <CreateApplication
          onClose={downloadClose}
          userId={data.userInfo.buid}
        />
      )}
      {editOpen && (
        <CreateUser
          onClose={onEditClose}
          data={{ ...data.userInfo, id: data.userInfo.buid }}
        />
      )}
    </>
  );
};

export default SingleUserActions;

const Wrapper = styled.div`
  position: sticky;
  top: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* background-color: white; */
  padding: 24px;
  /* border: 1px solid #a7aabd45; */
  border-radius: 12px;
  background-color: ${Colors.WhiteF9};
  border-bottom: 0.5px solid #a7aabd80;
`;

const H5 = styled.h5`
  font-size: 1rem;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
  padding-bottom: 10px;
  border-bottom: 0.5px solid #a7aabd80;
  width: 100%;
`;

const MiniWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Btn = styled.button`
  width: 180px;
  max-width: 180px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 24px;
  font-size: 0.75rem;
  font-weight: 600;
  border-radius: 8px;
  white-space: nowrap;
  text-transform: uppercase;

  & > svg {
    width: 20px;
    height: 20px;
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const EditUserBtn = styled(Btn)`
  color: ${Colors.Blue00};
  border: 1px solid ${Colors.Blue00};

  &:hover:not(:disabled) {
    background: #022e58;
    color: ${Colors.WhiteF9};
    transition: 0.3s ease-in-out;
  }
`;

const RiskBtn = styled(Btn)`
  color: ${Colors.Black31};
  border: 1px solid ${Colors.Black31};

  &:hover:not(:disabled) {
    background: ${Colors.Black31};
    color: ${Colors.WhiteF9};
    transition: 0.3s ease-in-out;
  }
`;

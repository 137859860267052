export enum ProfileSections {
  dashboard = "dashboard",
  applications = "applications",
  cases = "cases",
  documents = "documents",
  settings = "settings",
  caseManagement = "case-management",
  users = "clients",
  staffManagement = "staff-management",
  billing = "manage-billing",
}

export enum SettingsTabs {
  setting = "setting",
  password = "password",
  referral = "referral",
}

import React, { useState } from "react";
import styled from "styled-components";
// import { pricingPlans } from "./data";
import Constaint from "../../../layout/components/Constaint";
// import { FaArrowRight } from "react-icons/fa6";
import { CheckIcon } from "@heroicons/react/20/solid";
import CalendlyPopUp from "./CalendlyPopUp";

interface Tier {
  name: string;
  id: string;
  href: string;
  price: string;
  description: string;
  features: string[];
  featured: boolean;
  cta: string;
}

const tiers: Tier[] = [
  {
    name: "Per-Application",
    id: "tier-per-application",
    href: "#",
    price: "$49/application",
    description: "Only pay for what you use.",
    features: [
      "AI Risk Checker",
      "AI Interview Trainer",
      "Client Management",
      "Case Management",
      "Staff Management",
      "Technical Support",
    ],
    featured: false,
    cta: "Start now",
  },
  {
    name: "Standard Plan",
    id: "tier-standard",
    href: "#",
    price: "$99/monthly",
    description: "Essentials to manage your business effectively.",
    features: [
      "AI Risk Checker",
      "AI Interview Trainer",
      "Client Management",
      "Case Management",
      "Staff Management",
      "Technical Support",
      "1 Staff Seat (can add up to 2 seats for $20/month, per seat)",
    ],
    featured: false,
    cta: "Start now",
  },
  {
    name: "Premium Plan",
    id: "tier-premium",
    href: "#",
    price: "$199/monthly",
    description: "Advanced features for growing businesses.",
    features: [
      "Everything in Standard Plan",
      "Early access to new features",
      "Case Tracking",
      "3 staff seats (up to 2 additional seats available for $20/month, per seat)",
    ],
    featured: false,
    cta: "Start now",
  },
  {
    name: "Enterprise Plan",
    id: "tier-enterprise",
    href: "",
    price: "Coming Soon",
    description: "Tailored solutions for your business needs.",
    features: [
      "API Integration",
      "Get tailored solutions for your business needs",
    ],
    featured: true,
    cta: "Contact Sales",
  },
];

const Pricing = () => {
  const [open, setOpen] = useState(false);
  return (
    <Wrapper>
      <Constaint>
        <Contents>
          <Header>
            <h2 className="text-base font-semibold leading-7 text-primaryColor">
              Pricing
            </h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Choose the right plan for&nbsp;you
            </p>

            <p className="lg:w-[60%] mx-auto mt-6 text-gray-600 sm:text-center">
              Choose the ideal plan for your business. Whether you're a startup
              or a large enterprise, SeamlessVisa offers flexible plans to fit
              your budget and business needs.
            </p>
          </Header>
          <CalendlyPopUp open={open} onClose={() => setOpen(false)} />
          {/* <PricingContainer>
            {pricingPlans.map((plan, index) => (
              <PricingCard
                key={index}
                borderColor={plan.borderColor}
                isHighlighted={plan.isHighlighted}
              >
                <PlanTitle>{plan.title}</PlanTitle>
                <FeatureList>
                  {plan.features.map((feature, i) => (
                    <li key={i}>{feature}</li>
                  ))}
                </FeatureList>
                <Button
                  color={plan.buttonColor}
                  isHighlighted={plan.isHighlighted}
                  onClick={() => setOpen(true)}
                >
                  {plan.buttonText}
                  <ArrowRight>
                    <FaArrowRight />
                  </ArrowRight>
                </Button>
              </PricingCard>
            ))}
          </PricingContainer> */}
          <div className="grid max-w-md grid-cols-1 gap-8 mx-auto mt-10 isolate lg:mx-0 lg:max-w-none lg:grid-cols-2">
            {tiers.map((tier) => (
              <div
                key={tier.id}
                className={`rounded-3xl p-8 ring-1 xl:p-10 ${
                  tier.featured ? "bg-gray-900 ring-gray-900" : "ring-gray-200"
                } `}
              >
                {/* Tier Name */}
                <h3
                  id={tier.id}
                  className={`text-lg/8 font-semibold ${
                    tier.featured ? "text-white" : "text-gray-900"
                  }`}
                >
                  {tier.name}
                </h3>
                {/* Tier Description */}
                <p
                  className={`mt-4 text-sm/6 ${
                    tier.featured ? "text-gray-300" : "text-gray-600"
                  }`}
                >
                  {tier.description}
                </p>
                {/* Pricing */}
                <p className="flex items-baseline mt-6 gap-x-1">
                  <span
                    className={`text-4xl font-semibold tracking-tight ${
                      tier.featured ? "text-white" : "text-gray-900"
                    }`}
                  >
                    {tier.price}
                  </span>
                </p>
                {/* CTA Button */}
                <button
                  onClick={() => setOpen(true)}
                  aria-describedby={tier.id}
                  className={`mt-6 w-full block rounded-md px-3 py-2 text-center text-sm/6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                    tier.featured
                      ? "bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white"
                      : "bg-Blue00 nth- text-white shadow-sm hover:bg-blue-700 focus-visible:outline-blue-600"
                  }`}
                >
                  {tier.cta}
                </button>
                {/* Features List */}
                <ul
                  // role="list"
                  className={`mt-8 space-y-3 text-sm/6 xl:mt-10 ${
                    tier.featured ? "text-gray-300" : "text-gray-600"
                  }`}
                >
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon
                        aria-hidden="true"
                        className={`h-6 w-5 flex-none ${
                          tier.featured ? "text-white" : "text-Blue00"
                        }`}
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </Contents>
      </Constaint>
    </Wrapper>
  );
};

export default Pricing;

const Wrapper = styled.div``;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const Header = styled.div`
  text-align: center;
`;

// // Styled components for the pricing cards
// const PricingContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 20px;
//   padding: 20px;

//   @media screen and (min-width: 950px) {
//     flex-direction: row;
//     flex-wrap: nowrap;
//     padding: 40px;
//     justify-content: center;
//     padding: 30px;
//   }

//   @media screen and (min-width: 1024px) {
//   }
// `;

// const PricingCard = styled.div<{
//   borderColor: string;
//   isHighlighted?: boolean;
// }>`
//   border: 2px solid ${(props) => props.borderColor};
//   border-radius: 12px;
//   padding: 20px;
//   background: ${(props) => (props.isHighlighted ? "#0073ff" : "#fff")};
//   box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
//   color: ${(props) => (props.isHighlighted ? "#fff" : "#000")};
//   width: 100%;
//   max-width: 400px;
//   transition: transform 0.3s ease;

//   @media screen and (min-width: 1024px) {
//     width: 350px;
//   }

//   &:hover {
//     transform: translateY(-10px);
//   }
// `;

// const PlanTitle = styled.h3`
//   font-size: 24px;
//   margin-bottom: 20px;
//   font-weight: bold;
// `;

// const FeatureList = styled.ul`
//   list-style: disc;
//   padding: 0;
//   margin-bottom: 20px;
//   margin-left: 16px;

//   &:last-child {
//     list-style: none;
//   }

//   li {
//     font-size: 16px;
//     margin-bottom: 10px;
//   }
// `;

// const Button = styled.button<{ color: string; isHighlighted?: boolean }>`
//   background-color: ${(props) => props.color};
//   color: ${(props) => (props.isHighlighted ? "#0073ff" : "#fff")};
//   padding: 10px 20px;
//   border: none;
//   border-radius: 10px;
//   font-size: 16px;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   gap: 8px;

//   &:hover {
//     opacity: 0.9;
//   }
// `;

// const ArrowRight = styled.span`
//   font-size: 18px;
//   margin-left: 10px;
// `;

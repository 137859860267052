import React from "react";
import { SignUpType } from "./SignUp";
import { useNavigate, useSearchParams } from "react-router-dom";

const AuthNav = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const getType = searchParams.get("type") as SignUpType;

  return (
    <div className="flex items-center w-full [&>button]:flex-1  [&>button]:pb-2  font-medium border-b my-10">
      {[
        {
          label: "As an Individual",
          type: SignUpType.individual,
        },
        {
          label: "As a Business",
          type: SignUpType.business,
        },
      ].map((ev) => {
        const isActive = getType === ev.type;
        return (
          <button
            onClick={() => navigate(`?type=${ev.type}`)}
            className={` ${
              isActive ? "border-b-[2px] text-Blue00 border-Blue00" : ""
            } `}
          >
            {ev.label}
          </button>
        );
      })}
    </div>
  );
};

export default AuthNav;

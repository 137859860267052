import { useNavigate, useSearchParams } from "react-router-dom";
import "./auth.css";
import { WebPaths } from "../../../../routes/data";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../../../styles/Colors";
import { Form, Formik } from "formik";
import InputField from "../../components/formik fields/InputField";
import { LoginValidation } from "./validation";
import { useSignin, useSigninWithGoogle } from "../../../../hooks/auth/useAuth";
import Spinner from "../../../../utils/Spinner";
import Constaint from "../../layout/components/Constaint";
import ForgotPassword from "./ForgotPassword";
import { SignUpType } from "./SignUp";
import { useBusinessSignin } from "../../../../hooks/business/useAuth";
import { clearEligibilityStoreAndLocalStorage } from "../../checkEligibilty/components/data";
import { GoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import useLoginRes from "./useLoginRes";
import AuthNav from "./AuthNav";

interface FormData {
  email: string;
  password: string;
  agreement: boolean;
}

const Login = () => {
  const navigate = useNavigate();
  const { mutate, isLoading } = useSignin();
  const { mutate: businessMutate, isLoading: isBusinessLoading } =
    useBusinessSignin();
  const { onLoginSuccess, notVerifiedModal } = useLoginRes();
  const [forgotPass, setForgotPass] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const getType = searchParams.get("type") as SignUpType;

  const isBusinessType = getType === SignUpType.business;

  const { mutate: googleMutate, isLoading: googleLoading } =
    useSigninWithGoogle();

  const onSubmit = (values: FormData) => {
    const payload = {
      email: values.email,
      password: values.password,
    };

    if (isBusinessType) {
      businessMutate(payload, {
        onSuccess: (data) => {
          onLoginSuccess(data);
          clearEligibilityStoreAndLocalStorage();
        },
      });
    } else {
      mutate(payload, {
        onSuccess: onLoginSuccess,
      });
    }
  };

  useEffect(() => {
    if (getType !== SignUpType.individual && getType !== SignUpType.business) {
      navigate(`?type=${SignUpType.individual}`);
    }
  }, [getType, navigate]);

  const masterLoading = isLoading || isBusinessLoading || googleLoading;

  return (
    <Constaint>
      <div className="flex items-center justify-center">
        <div className="flex w-full mx-auto">
          <div className="flex-1 w-full max-w-2xl p-5 mx-auto sm:p-16">
            <h2 className="mb-8 text-2xl font-semibold text-center">Login</h2>
            <AuthNav />
            {
              <Formik
                initialValues={
                  {
                    email: "",
                    password: "",
                    // agreement: false,
                  } as FormData
                }
                onSubmit={onSubmit}
                validationSchema={LoginValidation}
              >
                <Former>
                  <InputField name="email" label="Email" placeholder="" />

                  <InputField
                    name="password"
                    label="Password"
                    placeholder=""
                    inputType="password"
                  />
                  <ResetPass onClick={() => setForgotPass(true)}>
                    Forgot password?
                  </ResetPass>

                  <SigninBtn disabled={masterLoading}>
                    {masterLoading && (
                      <Spinner color={Colors.White} size={14} />
                    )}
                    Login
                  </SigninBtn>
                </Former>
              </Formik>
            }

            {!isBusinessType && (
              <>
                <div className="flex items-center justify-center my-2">or</div>

                <div className="flex items-center justify-center ">
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      if (!credentialResponse.credential) return;
                      googleMutate(
                        { token: credentialResponse.credential },
                        {
                          onSuccess: onLoginSuccess,
                        }
                      );
                    }}
                    onError={() => {
                      toast.error("Login Failed");
                    }}
                  />
                </div>
              </>
            )}

            <p className="my-5 text-center">
              Don’t have {isBusinessType ? "a" : "an"} {getType} account?{" "}
              <button
                onClick={() =>
                  navigate(
                    `${WebPaths.Join}${
                      isBusinessType ? `?type=${SignUpType.business}` : ""
                    }`
                  )
                }
                className="text-[#0076E9]"
              >
                Sign up
              </button>
            </p>
          </div>
          {notVerifiedModal}
          {/* <AuthRightSession /> */}
        </div>

        {forgotPass && <ForgotPassword onClose={() => setForgotPass(false)} />}
      </div>
    </Constaint>
  );
};
export default Login;

const Btn = styled.button`
  padding: 0.8rem 0;
  text-align: center;
  width: 100%;
  border-radius: 0.6rem;
  transition: 0.3s all ease;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  &:disabled {
    cursor: not-allowed;
    background-color: ${Colors.GreyA7};
  }
`;

export const SigninBtn = styled(Btn)`
  background-color: ${Colors.Blue00};
  color: #fff;

  &:hover {
    background: #022e58;
  }
`;

export const GoogleBtn = styled(Btn)`
  background-color: ${Colors.White};
  margin-top: 1rem;
  color: ${Colors.Blue00};
  border: 1px solid ${Colors.Blue00};

  &:hover {
    background: ${Colors.Blue00};
    color: ${Colors.White};
  }
`;

const Former = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ResetPass = styled.div`
  font-weight: 500;
  color: ${Colors.Blue00};
  text-align: right;
  font-size: 0.85rem;

  width: max-content;
  margin-top: -20px;
  /* margin: 1rem 0 0; */
  cursor: pointer;
  margin-left: auto;

  &:hover {
    text-decoration: underline;
  }
`;

import styled from "styled-components";
import Colors from "../../../styles/Colors";
import { mediaObj } from "../../../styles/Media";

export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: flex-end;

  ${mediaObj.mobile} {
    flex-direction: column-reverse;
    gap: 12px;

    & > button {
      width: 100%;
    }
  }
`;

const Btn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 8px;
  min-height: 46px;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

export const BackBtn = styled(Btn)`
  background-color: ${Colors.DarkB3};
  color: ${Colors.Black31};
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
`;

export const SaveBtn = styled(Btn)`
  border: 1px solid ${Colors.Blue00};
  color: ${Colors.Blue00};

  transition: 0.3s all ease-out;

  &:hover:not(:disabled) {
    background-color: ${Colors.Blue00};
    color: ${Colors.White};
  }
`;

export const NextBtn = styled(Btn)`
  background-color: ${Colors.Blue00};
  color: ${Colors.White};
`;

export const HR = styled.div`
  height: 4px;
  width: 100%;
  background-color: ${Colors.Blue00};
  opacity: 10%;
  margin-top: 43px;
  margin-bottom: 40px;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
`;

export const ErrorText = styled.div`
  font-size: 1.1rem;
`;

export const RadioLabel = styled.div`
  margin-bottom: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${Colors.Black31};
  font-weight: bold;

  &.required:after {
    content: "*";
    font-size: 24px;
    line-height: 0;
    vertical-align: middle;
    color: #d90b2c;
  }

  ${mediaObj.mobile} {
    font-size: 0.875rem;
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  input {
    accent-color: red;
  }
`;

export const RadioFlex = styled.div<{ column?: boolean }>`
  display: flex;
  align-items: center;
  gap: 16px;
  white-space: nowrap;

  ${({ column }) => column && "flex-direction: column; align-items: start"};
`;

export const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  /* padding: 4rem 0; */
  overflow-x: auto;

  &:has(.options-open) {
    overflow-x: visible;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  & * {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.2px;
    color: #1b092c;
  }

  & td,
  & th {
    padding: 20px 0;
    padding-left: 30px;
    /* text-align: center; */
    /* white-space: nowrap; */
  }
`;

export const THEAD = styled.thead`
  border-top: 1px solid #a7aabd;
  border-bottom: 1px solid #a7aabd;
`;

export const TBODY = styled.tbody``;

export enum CellSize {
  sm = "10%",
  m = "20%",
  l = "30%",
}

interface THProps {
  width?: CellSize | string;
}

export const TH = styled.th<THProps>`
  width: ${({ width }) => width};
  font-weight: 600;

  &:first-child {
    padding-left: 34px;
  }
  &:last-child {
    padding-right: 34px;
  }
  text-align: left;
`;

interface TRProps {
  width?: CellSize;
}

export const TR = styled.tr<TRProps>`
  width: ${({ width }) => width};
`;

export const TD = styled.td`
  &:first-child {
    padding-left: 34px;
  }
  &:last-child {
    padding-right: 34px;
  }
`;

export const HeadFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 19px;
`;

export const FormWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 30px;

  flex-direction: column;

  @media screen and (min-width: 768px) {
    gap: 38px;
    flex-direction: row;
  }

  &:last-child {
    margin-bottom: 0;
  }
  & > div {
    flex: 1;
    width: 100%;
  }
`;

export const BtnFooterWrapper = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 2rem;
  align-items: center;
  justify-content: flex-end;

  & > div {
    margin-top: 0;
    justify-content: unset;
    width: max-content;
  }

  ${mediaObj.mobile} {
    flex-direction: column-reverse;

    & > div {
      width: 100%;

      & > button {
        width: 100%;
      }
    }
  }
`;

export const ModuleTitle = styled.h5`
  font-size: 1rem;
  font-weight: 600;
  line-height: 19px;

  margin-bottom: 1.24rem;
`;

import { ApiResponse } from "../../../types/types";
import request from "../../request";
import {
  ChoosePaymentModePayload,
  SubscribeAdditionalStaffSeatPayload,
  SubscriptionPaymentPayload,
  SubscriptionPaymentRes,
} from "./types";

export const choosePaymentMode = async (payload: ChoosePaymentModePayload) => {
  return await request.post(`business/choose-payment-mode`, payload);
};

export const subscriptionPayment = async (
  payload: SubscriptionPaymentPayload
) => {
  return await request.post<SubscriptionPaymentRes>(
    `business/subscription-payment`,
    payload
  );
};

export const businessSubscriptionSuccess = async (session_id: string) => {
  return await request.get<ApiResponse>(
    `business/subscription-success?session_id=${session_id}`
  );
};

export const businessSubscriptionFailure = async (session_id: string) => {
  return await request.get<ApiResponse>(
    `business/subscription-cancel?session_id=${session_id}`
  );
};

export const subscribeAdditionalStaffSeat = async (
  payload: SubscribeAdditionalStaffSeatPayload
) => {
  return await request.post<SubscriptionPaymentRes>(
    `business/subscribe-additional-staff-seat`,
    payload
  );
};

export const businessSubscriptionStaffSeatSuccess = async (
  session_id: string
) => {
  return await request.get<ApiResponse>(
    `business/staff-subscription-success?session_id=${session_id}`
  );
};

export const businessSubscriptionStaffSeatFailure = async (
  session_id: string
) => {
  return await request.get<ApiResponse>(
    `business/staff-subscription-cancel?session_id=${session_id}`
  );
};

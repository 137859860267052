import { motion } from "framer-motion";
import { FC, useState } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import DocReviewModal from "./DocReviewModal";
import { DocReviewTypeValues } from "./types";
import LoadPdf from "./LoadPdf";
import { FileDetail } from "../../../../../../../api/n400/types";
import Overlay from "../../../../../checkEligibilty/components/modals/Overlay";
import { ContainerVariants } from "../../../../../checkEligibilty/components/modals/variant";
import { mediaObj } from "../../../../../../../styles/Media";

interface Props {
  onClose: () => void;
  data: FileDetail;
  isAgent?: boolean;
}

const DocViewModal: FC<Props> = ({ onClose, data, isAgent }) => {
  const [open, setOpen] = useState<DocReviewTypeValues>();
  const regex = new RegExp("[^.]+$");
  const extension = data.filename.match(regex)?.[0];
  const isPdf = extension === "pdf";

  // const onApprove = () => {
  //   setOpen(DocReviewTypeValues.recommended);
  // };

  // const onReject = () => {
  //   setOpen(DocReviewTypeValues.notRecommended);
  // };

  const onCloseReview = () => {
    setOpen(undefined);
  };

  const closeAll = () => {
    onClose();
    onCloseReview();
  };

  // https://firebasestorage.googleapis.com/v0/b/immigration-hub-bd107.appspot.com/o/1708987862536-378786502-blue_plus.png

  return open ? (
    <DocReviewModal
      data={data}
      type={open}
      onClose={onCloseReview}
      closeAll={closeAll}
      isAgent={isAgent}
    />
  ) : (
    createPortal(
      <Overlay onClose={onClose}>
        <Wrapper
          onClick={(e) => e.stopPropagation()}
          variants={ContainerVariants}
        >
          {/* "https://firebasestorage.googleapis.com/v0/b/immigration-hub-bd107.appspot.com/o/1708891666402-912050380-Seemlessvisa%20Security%20Scan.pdf?alt=media&amp;token=17b02ade-a623-47d8-97d5-d3477293ca31 */}
          {/* <img src=" alt=""></img> */}
          <ContentWrapper>
            {isPdf ? (
              <LoadPdf url={data.url} />
            ) : (
              // <IFrame
              //   // src={`http://docs.google.com/gview?url=https://firebasestorage.googleapis.com/v0/b/immigration-hub-bd107.appspot.com/o/1708987862536-378786502-blue_plus.png?alt=media&embedded=true`}
              //   src={`https://docs.google.com/viewer?embedded=true&url=${data.url}`}
              // />
              <Img src={data.url} alt="" />
            )}
          </ContentWrapper>

          {/* <BtnWrapper>
            <RejectBtn onClick={onReject}>Reject</RejectBtn>
            <ApproveBtn onClick={onApprove}>Approve</ApproveBtn>
          </BtnWrapper> */}
        </Wrapper>
      </Overlay>,
      document.body
    )
  );
};

export default DocViewModal;

const ContentWrapper = styled.div`
  width: 100%;
  height: 50vh;
  overflow-y: auto;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
`;

const Wrapper = styled(motion.div)`
  width: 50vw;
  height: max-content;
  background: #ffffff;
  border-radius: 12px;
  padding: 40px 32px;

  ${mediaObj.smallDesktop} {
    margin: auto;
    width: 60vw;
    padding-left: 5%;
    padding-right: 5%;
  }

  ${mediaObj.tablet} {
    width: 80vw;
  }

  ${mediaObj.bigMobile} {
    width: 90vw;
  }
`;

// const BtnWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   gap: 20px;
//   margin-top: 20px;
//   /* justify-content: space-between; */

//   ${mediaObj.bigMobile} {
//     flex-wrap: wrap;
//     gap: 10px;
//     & > button {
//       flex: 1;
//     }
//   }
// `;

// const Btn = styled.button`
//   border: none;
//   cursor: pointer;
//   height: 50px;
//   font-weight: 500;
//   font-size: 0.9375rem;
//   line-height: 1.5rem;
//   padding: 13px 45px;
//   border-radius: 30px;
//   white-space: nowrap;
//   min-width: 160px;

//   :disabled {
//     opacity: 0.8;
//     cursor: not-allowed;
//   }
// `;

// const ApproveBtn = styled(Btn)`
//   color: white;
//   background: ${Colors.Blue00};
// `;

// const RejectBtn = styled(Btn)`
//   background: #d50000;
//   color: #ffffff;
// `;

import React, { useEffect, useState } from "react";
import Constaint from "../../../layout/components/Constaint";
import { AiOutlineComment } from "react-icons/ai";
import { WebPaths } from "../../../../../routes/data";
import { Link } from "react-router-dom";

const FeaturedVideo = () => {
  const [dimensions, setDimensions] = useState({ width: 560, height: 315 });

  useEffect(() => {
    const updateDimensions = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth >= 1024) {
        // Large screens (Desktop)
        setDimensions({ width: 800, height: 450 });
      } else if (windowWidth >= 768) {
        // Medium screens (Tablet)
        setDimensions({ width: 640, height: 360 });
      } else {
        // Small screens (Mobile)
        setDimensions({ width: 360, height: 202 });
      }
    };
    // Initial check
    updateDimensions();

    // Update dimensions on resize
    window.addEventListener("resize", updateDimensions);

    // Cleanup on unmount
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div>
      <Constaint>
        <div className="grid place-content-center p-16 mb-24 mt-10">
          <iframe
            width={dimensions.width}
            height={dimensions.height}
            className="border-t-[16px] border-Blue00"
            src="https://www.youtube.com/embed/CKJp2CoZSOY?si=UOnKlJjsMBU5p_Ps&amp;controls=1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>

          <Link
            to={WebPaths.FiBiVoice}
            className="group bg-[#EAC5FC] grow text-sm font-semibold text-Black shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 flex items-center gap-x-4 first:justify-between w-fit mx-auto mt-10"
          >
            <span className="px-4">Start Mock Interview</span>
            <div className="bg-[#A881DB] group-hover:bg-[#A881DB]/40 p-3 text-white duration-150">
              <AiOutlineComment className="h-6 w-6" />
            </div>
          </Link>
        </div>
      </Constaint>
    </div>
  );
};

export default FeaturedVideo;

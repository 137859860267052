export enum DocReviewTypeValues {
  recommended = "recommended",
  notRecommended = "not-recommended",
}

export interface saveDS160PersonalInfoObj {
  lastName: string;
  givenNames: string;
  fullName: string;
  doesNotApply: boolean;
  anyOtherNames: string;
  otherFirstName: string;
  otherMiddleName: string;
  otherLastName: string;
  teleCode: string;
  teleCodeSurname: string;
  teleCodeGivenNames: string;
  sex: string;
  // maritalStatus: string;
  stateAndProvince: string;
  dateOfBirth: string;
  city: string;
  countryOrRegion: string;
  location: string;
}

export interface saveDS160PersonalInfo2Obj {
  countryOrRegion: string;
  heldAnotherNationality: string;
  prOfOtherCountry: string;
  otherCountryOrRegion: string;
  nin: string;
  doesNotApplyNin: boolean;
  SSN: string;
  doesNotApplySSN: boolean;
  idNumber: string;
  doesNotApplyIdNumber: boolean;
}

export interface saveDS160TravelObj {
  purposeOfTrip: string;
  specific: string;
  haveYouMadeSpecificTravelPlans: string;
  dateOfArrival: string;
  arrivalFlight: string;
  arrivalCity: string;
  dateOfDeparture: string;
  departureFlight: string;
  departureCity: string;
  location: string;
  intendedDateOfArrival: string;
  intendedLengthOfStayDuration: string;
  intendedLengthOfStayDropDown: string;
  streetAddressLine1: string;
  streetAddressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  personPaying: string;
  //
  personPayingSurnames: string;
  personPayingGivenNames: string;
  personPayingTelephone: string;
  personPayingEmail: string;
  doesNotApplyPersonPayingEmail: boolean;
  personPayingRelationship: string;
  personPayingIsSameAddress: string;
  //
  personPayingStreetAddressLine1: string;
  personPayingStreetAddressLine2: string;
  personPayingCity: string;
  personPayingState: string;
  personPayingZipCode: string;
  personPayingCountry: string;
  //
  doesNotApplyPersonPayingState: boolean;
  doesNotApplyPersonPayingZipCode: boolean;
  //
  companyPayingName: string;
  companyPayingTelephone: string;
  companyPayingRelationship: string;
  companyPayingStreetAddressLine1: string;
  companyPayingStreetAddressLine2: string;
  companyPayingCity: string;
  companyPayingCountry: string;
  companyPayingState: string;
  doesNotApplyCompanyPayingState: boolean;
  companyPayingZipCode: string;
  doesNotApplyCompanyPayingZipCode: boolean;
}

export interface saveDS160TravelCompanionsObj {
  otherPersonsTravelling: string;
  travellingAsPartOfGroup: string;
  surname: string;
  givenName: string;
  relationshipwithPerson: string;
  groupName: string;
}

export interface saveDS160PreviousUSTravelObj {
  everBeenToTheUs: string;
  everIssuedUsVisa: string;
  dateArrived: string;
  lengthOfStayDuration: string;
  lengthOfStayDropdown: string;
  haveDriverLicense: string;
  driverLicenseNumber: string;
  stateOfDriverLicense: string;
  lastVisaDate: string;
  visaNumber: string;
  doNotKnowVisaNumber: boolean;
  applyingForSameTypeOfVisa: string;
  applyingInSameLocation: string;
  visaEverStolen: string;
  visaStolenYear: string;
  visaStolenExplanation: string;
  visaEverRevoked: string;
  visaRevokeYear: string;
  visaRevokeExplanation: string;
  everBeenRefusedUsVisa: string;
  anyoneFiledPetition: string;
  //
  beenTenPrinted: string;
  beenLPR: string;
  everBeenRefusedUsVisaExplanation: string;
  beenLPRExplanation: string;
  anyoneFiledPetitionExplanation: string;
}
export interface saveDS160AddressAndPhoneObj {
  homeAddress: string;
  streetAddressLine1: string;
  streetAddressLine2: string;
  city: string;
  stateOrProvince: string;
  zipCode: string;
  countryOrRegion: string;
  mailAddressSameAsHomeAddress: string;
  mailHomeAddress: string;
  mailStreetAddressLine1: string;
  mailStreetAddressLine2: string;
  mailCity: string;
  mailStateOrProvince: string;
  mailZipCode: string;
  mailCountryOrRegion: string;
  primaryPhoneNumber: string;
  secondaryPhoneNumber: string;
  doesNotApplySecPN: boolean;
  workPhoneNumber: string;
  doesNotApplyWorkPN: boolean;
  additionalEmail: string;
  anyOtherPhoneNumber: string;
  additionalPhoneNumber: string;
  anyOtherEmail: string;
  socialMedia: string;
  socialMediaIdentifier: string;
  presenceOnWebOrApp: string;
  additionalSocialMedia: string;
  //
  email: string;
  additionalSocialMediaHandle: string;
}
export interface saveDS160PassportObj {
  passportType: string;
  passportNumber: string;
  passportBookNumber: string;
  doesNotApply: boolean;
  country: string;
  city: string;
  stateProvince: string;
  countryOrRegion: string;
  issuanceDate: string;
  expirationDate: string;
  everLostPassport: string;
  //
  passportLostPassportNumber: string;
  passportLostCountry: string;
  passportLostExplain: string;
  passportLostPassportNumberDoNotKnow: string;
}
export interface saveDS160USContactObj {
  surname: string;
  givenNames: string;
  doesNotApply: boolean;
  organizationName: string;
  relationshipToYou: string;
  usStreetAddressLine1: string;
  usStreetAddressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  phoneNumber: string;
  emailAddress: string;
  emailDoesNotApply: boolean;
}
export interface saveDS160FamilyObj {
  fatherSurname: string;
  fatherGivenNames: string;
  fatherDateOfBirth: string;
  isFatherInTheUs: string;
  fatherStatus: string;
  motherSurname: string;
  doNotKnowSurname: boolean;
  motherGivenNames: string;
  doNotKnowGivenNames: boolean;
  motherDateOfBirth: string;
  isMotherInTheUs: string;
  motherStatus: string;
  immediateRelativesInTheUs: string;
  surname: string;
  givenNames: string;
  relativeStatus: string;
  anyOtherRelatives: string;
}

export interface saveDS160WETPresentObj {
  primaryOccupation: string;
  presentEmployerOrSchoolName: string;
  streetAddressLine1: string;
  streetAddressLine2: string;
  city: string;
  stateOrProvince: string;
  zipCode: string;
  phoneNumber: string;
  countryOrRegion: string;
  startDate: string;
  monthlyIncome: string;
  doesNotApply: boolean;
  dutiesDescription: string;
}

export interface saveDS160WETPreviousObj {
  wereYouPreviouslyEmployed: string;
  employerName: string;
  employerStreetAddressLine1: string;
  employerStreetAddressLine2: string;
  city: string;
  stateOrProvince: string;
  zipCode: string;
  phoneNumber: string;
  countryOrRegion: string;
  jobTitle: string;
  supervisorSurname: string;
  doesNotApplySurname: string;
  supervisorGivenNames: string;
  doesNotApplyGivenNames: string;
  employmentDateFrom: string;
  employmentDateTo: string;
  dutiesDescription: string;
  attendedAnyEduInstitution: string;
  nameOfInstitute: string;
  instituteStreetAddressLine1: string;
  instituteStreetAddressLine2: string;
  instituteCity: string;
  instituteStateOrProvince: string;
  instituteZipCode: string;
  country: string;
  courseOfStudy: string;
  dateOfAttendanceFrom: string;
  dateOfAttendanceTo: string;
}

export interface saveDS160WETAdditionalObj {
  belongToClanOrTribe: string;
  travelToCountriesOrRegions: string;
  belongToCharitableOrg: string;
  anySpecialisedSkills: string;
  everServedInTheMilitary: string;
  tribeName: string;
  languages: string;
  countryOrRegion: string;
  organizationName: string;
  skillExplanation: string;
  militaryCountryOrRegion: string;
  branchOfService: string;
  rank: string;
  militarySpecialty: string;
  dateOfServiceFrom: string;
  dateOfServiceTo: string;
}

export interface saveDS160SEVAdditionalContactObj {
  surname: string;
  givenNames: string;
  streetAddressLine1: string;
  streetAddressLine2: string;
  city: string;
  stateOrProvince: string;
  doesNotApplyStateOrProvince: string;
  zipCode: string;
  doesNotApplyZipCode: string;
  countryOrRegion: string;
  telephoneNumber: string;
  doesNotApplyTelephoneNumber: string;
  emailAddress: string;
  doesNotApplyEmailAddress: string;
}

export interface saveDS160SivesObj {
  sevisID: string;
  nameOfSchool: string;
  courseOfStudy: string;
  streetAddressLine1: string;
  streetAddressLine2: string;
  city: string;
  stateOrProvince: string;
  zipCode: string;
}

export interface saveDS160SecurityBackgroundP1Obj {
  partOneI: string;
  explanationPartOneI: string;
  partOneII: string;
  explanationPartOneII: string;
  partOneIII: string;
  explanationPartOneIII: string;
}

export interface saveDS160SecurityBackgroundP2Obj {
  partTwoI: string;
  explanationPartTwoI: string;
  partTwoII: string;
  explanationPartTwoII: string;
  partTwoIII: string;
  explanationPartTwoIII: string;
  partTwoIV: string;
  explanationPartTwoIV: string;
  partTwoV: string;
  explanationPartTwoV: string;
  partTwoVI: string;
  explanationPartTwoVI: string;
  partTwoVII: string;
  explanationPartTwoVII: string;
}

export interface saveDS160SecurityBackgroundP3Obj {
  partThreeI: string;
  explanationPartThreeI: string;
  partThreeII: string;
  explanationPartThreeII: string;
  partThreeIII: string;
  explanationPartThreeIII: string;
  partThreeIV: string;
  explanationPartThreeIV: string;
  partThreeV: string;
  explanationPartThreeV: string;
  partThreeVI: string;
  explanationPartThreeVI: string;
  partThreeVII: string;
  explanationPartThreeVII: string;
  partThreeVIII: string;
  explanationPartThreeVIII: string;
  partThreeIX: string;
  explanationPartThreeIX: string;
  partThreeX: string;
  explanationPartThreeX: string;
  partThreeXI: string;
  explanationPartThreeXI: string;
}

export interface saveDS160SecurityBackgroundP4Obj {
  partFourI: string;
  explanationPartFourI: string;
  partFourII: string;
  explanationPartFourII: string;
}

export interface saveDS160SecurityBackgroundP5Obj {
  partFiveI: string;
  explanationPartFiveI: string;
  partFiveII: string;
  explanationPartFiveII: string;
  partFiveIII: string;
  explanationPartFiveIII: string;
}

export interface saveDS160MaritalStatusObj {
  maritalStatus: string;
  personSurname: string;
  personGivenName: string;
  personDateOfBirth: string;
  personCountry: string;
  personCity: string;
  personCityDoNotKnow: boolean;
  personBirthCountry: string;
  personAddress: string;
  personAddressStreetAddressLine1: string;
  personAddressStreetAddressLine2: string;
  personAddressCity: string;
  personAddressState: string;
  personAddressZipCode: string;
  personAddressCountry: string;
  doesNotApplyPersonAddressState: boolean;
  doesNotApplyPersonAddressZipCode: boolean;
}

export interface GetDs160Obj {
  getStarted: GetDS160GetStartedRes;
  Security: {
    partFive: saveDS160SecurityBackgroundP5Obj;
    partFour: saveDS160SecurityBackgroundP4Obj;
    partOne: saveDS160SecurityBackgroundP1Obj;
    partThree: saveDS160SecurityBackgroundP3Obj;
    partTwo: saveDS160SecurityBackgroundP2Obj;
  };
  addressAndPhone: saveDS160AddressAndPhoneObj;
  family: saveDS160FamilyObj;
  passport: saveDS160PassportObj;
  personalInfo: {
    personalOne: saveDS160PersonalInfoObj;
    personalTwo: saveDS160PersonalInfo2Obj;
  };
  previousUsTravel: saveDS160PreviousUSTravelObj;
  studentExchangeVisa: {
    additionalContact: saveDS160SEVAdditionalContactObj;
    sevis: saveDS160SivesObj;
  };
  travel: saveDS160TravelObj;
  travelCompanions: saveDS160TravelCompanionsObj;
  usContact: saveDS160USContactObj;
  workEducationTraining: {
    additional: saveDS160WETAdditionalObj;
    present: saveDS160WETPresentObj;
    previous: saveDS160WETPreviousObj;
  };
  maritalStatus: saveDS160MaritalStatusObj;
}

export interface GetDS160GetStartedRes {
  url: string;
}

import { FC } from "react";
import { useNavigate } from "react-router-dom";
import ItemAction from "../../../../../components/table/ItemAction";
import { GetBusinessApplicationsObj } from "../../../../../../../api/business/applications/types";
import useOpener from "../../../../../../../hooks/useOpener";
import DownloadForms from "../../../../../components/DownloadForms";
import useBusinessContinueToForm from "../../../../../../../hooks/useBusinessContinueToForm";
import { FormCategories } from "../../../../../../../routes/type";
import { clearEligibilityStoreAndLocalStorage } from "../../../../../checkEligibilty/components/data";
import { BusinessQueryKey } from "../../../../../../../api/utils/types";

interface Props {
  data: GetBusinessApplicationsObj;
}

const CaseAction: FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  const { businessContinueToForm } = useBusinessContinueToForm();
  const { open, onClose, onOpen } = useOpener();

  const onView = () => {
    navigate(
      `?active=case-management&${BusinessQueryKey.appId}=${data.applicationId}`
    );
  };

  const actionOptions = () => {
    let temp = [
      {
        name: "View Details",
        onClick: onView,
      },
      {
        name: `Continue To ${data.applicationInfo ? "Form" : "Eligibility"}`,
        onClick: () => {
          if (!data.applicationInfo) {
            clearEligibilityStoreAndLocalStorage();
          }
          businessContinueToForm(data);
        },
      },
    ];

    if (
      data.applicationInfo?.formCategory === FormCategories.ds160
        ? data.applicationInfo.isFormCompleted
        : data.applicationInfo?.isPaid
    ) {
      temp = [
        ...temp,
        {
          name: "Download Forms",
          onClick: onOpen,
        },
      ];
    }

    return temp;
  };

  return (
    <>
      <ItemAction options={actionOptions()} />

      {open && (
        <DownloadForms
          onClose={onClose}
          caseType={data.applicationType}
          filingURL={""}
          formDownloadUrl={""}
          g1145URL={""}
        />
      )}
    </>
  );
};

export default CaseAction;

import { AiOutlineComment } from "react-icons/ai";
import { BiSolidBusiness } from "react-icons/bi";
import { RiUser6Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import heroImage from "../../../../images/web/general-page-hero.jpg";
import { WebPaths } from "../../../../routes/data";

const Hero = () => {
  return (
    <div className="relative overflow-hidden bg-white isolate">
      <svg
        aria-hidden="true"
        className="absolute inset-0 -z-10 h-full w-full stroke-blue-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
      >
        <defs>
          <pattern
            x="50%"
            y={-1}
            id="0787a7c5-978c-4f66-83c7-11c213f99cb7"
            width={200}
            height={200}
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <rect
          fill="url(#0787a7c5-978c-4f66-83c7-11c213f99cb7)"
          width="100%"
          height="100%"
          strokeWidth={0}
        />
      </svg>
      <div className="px-6 py-10 pb-24 mx-auto max-w-7xl sm:pb-24 lg:flex lg:px-8 ">
        <div className="max-w-2xl mx-auto lg:mx-0 lg:flex-shrink-0 lg:pt-8">
          <h1 className="mt-10 text-5xl font-semibold tracking-tight capitalize text-pretty font-spartan text-Blue00 sm:text-7xl">
            Avoid U.S. visa denial and boost your{" "}
            <span className="font-semibold font-spartan text-Blue00">
              approval
            </span>{" "}
            chances
          </h1>
          <p className="mt-8 text-lg font-medium text-gray-500 text-pretty sm:text-xl/8">
            Join thousands of users to uncover application errors, access FiBi
            AI for visa interview practice, and improve your success chances.
          </p>
          <div className="flex flex-col items-start gap-4 mt-10 md:flex-row md:items-center">
            <Link
              to={WebPaths.Individuals}
              className="flex items-center justify-between w-64 pl-5 text-sm font-semibold duration-150 shadow-sm group bg-Blue00/40 group-hover:bg-Blue00 grow text-Black md:w-auto"
            >
              <span className="">For Individuals</span>
              <div className="p-4 text-white duration-150 bg-Blue00 group-hover:bg-Blue00/40">
                <RiUser6Line className="w-5 h-5" />
              </div>
            </Link>

            <Link
              to={WebPaths.Businesses}
              className="group bg-[#00C1A3] grow text-sm font-semibold text-Black shadow-sm flex items-center justify-between w-64 md:w-auto duration-150 pl-5"
            >
              <span className="">For Businesses</span>
              <div className="bg-[#1F8A79] group-hover:bg-[#1F8A79]/40 p-4 text-white duration-150">
                <BiSolidBusiness className="w-6 h-6" />
              </div>
            </Link>

            <Link
              to={WebPaths.FiBiAi}
              className="group bg-[#EAC5FC] grow text-sm font-semibold text-Black shadow-sm flex items-center justify-between w-64 md:w-auto duration-150 pl-5"
            >
              <span className="">Try FiBi AI interview</span>
              <div className="bg-[#A881DB] group-hover:bg-[#A881DB]/40 p-4 text-white duration-150">
                <AiOutlineComment className="w-6 h-6" />
              </div>
            </Link>
          </div>
        </div>
        <div className="flex max-w-2xl mx-auto mt-16 sm:mt-24 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
          <div className="flex-none w-full lg:max-w-none">
            <div className="p-2 -m-2 rounded-xl bg-gray-900/5 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
              <img
                alt="App screenshot"
                src={heroImage}
                // width={2432}
                // height={1442}
                className="lg:w-[50rem] h-[720px] object-cover rounded-md shadow-2xl ring-1 ring-gray-900/10"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Hero;

import { motion } from "framer-motion";
import React, { FC } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import Overlay from "../../../../../checkEligibilty/components/modals/Overlay";
import { ContainerVariants } from "../../../../../checkEligibilty/components/modals/variant";
import { mediaObj } from "../../../../../../../styles/Media";
import Colors from "../../../../../../../styles/Colors";
import { Form, Formik } from "formik";
import Spinner from "../../../../../../../utils/Spinner";
import { Modify, SingleOption } from "../../../../../../../types/types";
import { CreateBusinessApplicationPayload } from "../../../../../../../api/business/applications/types";
import SelectField from "../../../../../components/formik fields/SelectField";
import { useCreateBusinessApplication } from "../../../../../../../hooks/business/useBusinessApplications";
import { useGetBusinessUsers } from "../../../../../../../hooks/business/useBusinessUsers";
import { FormatingData, initSelect } from "../../../../../components/data";
import { GetBusinessUsersObj } from "../../../../../../../api/business/users/types";
import { toast } from "react-toastify";
import { BusinessStartApplicationValidation } from "./validation";
import { WebPaths } from "../../../../../../../routes/data";
import { useNavigate } from "react-router-dom";
import { FormCategories } from "../../../../../../../routes/type";
import { BusinessQueryKey } from "../../../../../../../api/utils/types";
import { applicationTypeData } from "./data";
import { clearEligibilityStoreAndLocalStorage } from "../../../../../checkEligibilty/components/data";

interface Props {
  onClose: () => void;
  userId?: string;
}

export interface BusinessCreateApplicationFormData
  extends Modify<
    Omit<CreateBusinessApplicationPayload, "userId" | "applicationType">,
    {
      user: SingleOption | null;
      applicationType: SingleOption | null;
    }
  > {}

const CreateApplication: FC<Props> = ({ onClose, userId }) => {
  const navigate = useNavigate();
  const { mutate, isLoading } = useCreateBusinessApplication();
  const { data: UserData, isLoading: usersLoading } = useGetBusinessUsers({});

  const formattedUserData =
    FormatingData<GetBusinessUsersObj>("id", "name", UserData?.usersData) || [];

  return createPortal(
    <Overlay onClose={onClose}>
      <Wrapper
        onClick={(e) => e.stopPropagation()}
        variants={ContainerVariants}
      >
        <Header>Start Application</Header>

        <Formik
          initialValues={
            {
              applicationType: initSelect(
                applicationTypeData,
                FormCategories.ds160
              ),
              user: initSelect(formattedUserData, userId),
            } as BusinessCreateApplicationFormData
          }
          enableReinitialize
          validationSchema={BusinessStartApplicationValidation}
          onSubmit={(values) => {
            const { user, applicationType } = values;

            if (!user || !applicationType) return;

            const payload = {
              applicationType: applicationType.value,
              userId: user.value,
            };

            mutate(payload, {
              onSuccess: (data) => {
                onClose();
                toast.success("Sucessfully");
                const formCategory = payload.applicationType;
                navigate(
                  `${WebPaths.checkEligibility}/${formCategory}?${BusinessQueryKey.appId}=${data.data.applicationId}`
                );
                clearEligibilityStoreAndLocalStorage();
              },
            });
          }}
        >
          <Form className="flex flex-col gap-4 mt-5">
            <SelectField
              name="user"
              label="Client"
              options={formattedUserData}
              isLoading={usersLoading}
              isDisabled={!!userId}
            />
            <SelectField
              name="applicationType"
              label="Application Type"
              // options={newApplicationData}
              options={applicationTypeData}
              isDisabled
            />
            <BtnWrapper>
              <CancelBtn onClick={onClose} disabled={isLoading} type="button">
                Cancel
              </CancelBtn>
              <ConfirmBtn
                disabled={isLoading}
                type="submit"
                className="mx-auto"
              >
                {isLoading ? <Spinner color={Colors.White} /> : "Start"}
              </ConfirmBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      </Wrapper>
    </Overlay>,
    document.body
  );
};

export default CreateApplication;

const Wrapper = styled(motion.div)`
  width: 437px;
  height: max-content;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 12px;
  padding: 40px 32px;

  ${mediaObj.smallDesktop} {
    margin: auto;
    width: 60vw;
    padding-left: 5%;
    padding-right: 5%;
  }

  ${mediaObj.tablet} {
    width: 80vw;
  }

  ${mediaObj.bigMobile} {
    width: 90vw;
  }
`;

const Header = styled.h2`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.625rem;
  text-align: center;
  color: #1b092c;
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 8px;

  & > button {
    flex: 1;
  }

  ${mediaObj.bigMobile} {
    flex-wrap: wrap-reverse;
    gap: 10px;
  }
`;

const Btn = styled.button`
  border: none;
  cursor: pointer;
  height: 50px;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  padding: 13px 45px;
  border-radius: 30px;
  white-space: nowrap;
  min-width: 160px;

  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const CancelBtn = styled(Btn)`
  background: #e6e6e6;
  color: #1b092c99;
`;

const ConfirmBtn = styled(Btn)`
  width: 100%;
  background: ${Colors.Blue00};
  color: #ffffff;
  transition: all 0.3s ease;
  border: 1px solid ${Colors.Blue00};

  &:hover:not(:disabled) {
    color: ${Colors.Blue00};
    background: #ffffff;
  }
`;

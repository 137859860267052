import React from "react";
import {
  TBODY,
  TD,
  TH,
  THEAD,
  TR,
  Table,
  TableWrapper,
} from "../../../../components/styles";
import Colors from "../../../../../../styles/Colors";
import styled from "styled-components";
import { useSnapshot } from "valtio";
import { userDataStore } from "../../../../../../store/userData";
import { getFormatedFormName } from "../../../../components/data";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import StatusTag from "../../case/components/StatusTag";
import useBasedOnFormPaid from "../../../../../../hooks/useBasedOnFormPaid";

const DocumentsTable = () => {
  const { getBasedOnFormPaid } = useBasedOnFormPaid();
  const [searchParams, setSearchParams] = useSearchParams();
  const { userData } = useSnapshot(userDataStore);
  const tableData = userData?.caseHistory.filter((ev) =>
    getBasedOnFormPaid(ev.caseType)
  );
  // const tableData = userData?.caseHistory.filter((ev) => ev.Payment);

  const onView = (caseType: string) => {
    searchParams.set("form", caseType);
    setSearchParams(searchParams);
  };

  return (
    <TableWrapper>
      <Table>
        <THEAD>
          <TR>
            <TH>Case Type</TH>
            <TH>Date Submitted</TH>
            <TH>Status</TH>
            <TH></TH>
          </TR>
        </THEAD>

        <TBODY>
          {tableData?.length === 0 ? (
            <TR>
              <TD colSpan={6}>
                <NoData>You don't have any document review history yet.</NoData>
              </TD>
            </TR>
          ) : (
            tableData?.map((ev, i) => {
              return (
                <TR key={i}>
                  <TD>
                    <Texter>{getFormatedFormName(ev.caseType) || "--"}</Texter>
                  </TD>
                  <TD>
                    {ev.dateSubmitted
                      ? dayjs(ev.dateSubmitted).format("DD/MM/YY")
                      : "--"}
                  </TD>
                  <TD>
                    <StatusTag
                      progress={ev.Status}
                      caseStatus={ev.case_Status}
                    />
                  </TD>
                  <TD>
                    <BtnWrapper>
                      <ViewBtn onClick={() => onView(ev.caseType)}>
                        View
                      </ViewBtn>
                    </BtnWrapper>
                  </TD>
                </TR>
              );
            })
          )}
        </TBODY>
      </Table>
    </TableWrapper>
  );
};

export default DocumentsTable;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 13px;
`;

const Btn = styled.button`
  padding: 4px 11px;
  border: 1px solid;
  font-size: 0.75rem;
  border-radius: 4px;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const ViewBtn = styled(Btn)`
  border-color: ${Colors.Blue00};
  color: ${Colors.Blue00};
`;

const Texter = styled.span`
  text-transform: uppercase;
`;

const NoData = styled.div`
  text-transform: capitalize;
  padding: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

import React, { FC } from "react";
import styled from "styled-components";
import Colors from "../../../../../../styles/Colors";
import { HR } from "../../styles";
import { Form, Formik } from "formik";
import InputField from "../../../../components/formik fields/InputField";
import UploadBox from "./upload/UploadBox";
import Spinner from "../../../../../../utils/Spinner";
import { AdditionalDocumentsValidation } from "./validation";
import { useSearchParams } from "react-router-dom";
import { getFormatedFormName } from "../../../../components/data";
import SelectField from "../../../../components/formik fields/SelectField";
import { DocTypeOthersValues } from "./data/data";
import { SingleOption } from "../../../../../../types/types";
import { mediaObj } from "../../../../../../styles/Media";

export const additionalKeyText = "additional_";

interface InitialValuesProps {
  doc_label: string;
  doc_type: SingleOption | null;
  file: File | null;
}

interface Props {
  onSubmit: (
    params: File,
    name: string,
    fileId?: string,
    onSuccessful?: () => void
  ) => void;
  loadingField: string;
  loadingState: boolean;
  docType?: SingleOption[];
}

const AdditionalDoc: FC<Props> = ({
  onSubmit,
  loadingField,
  loadingState,
  docType,
}) => {
  const [searchParams] = useSearchParams();
  const getType = searchParams.get("form");

  const getInitialDocType = () => {
    if (docType) return null;
    return {
      label: DocTypeOthersValues.Others,
      value: DocTypeOthersValues.Others,
    };
  };
  const initialValues: InitialValuesProps = {
    doc_label: "",
    doc_type: getInitialDocType(),
    file: null,
  };

  const getIsLoading = (name: string) => {
    return loadingField === name && loadingState;
  };

  const getDocKey = (params: string) => {
    const temp = params.toLowerCase().replaceAll(" ", "_");
    return `${additionalKeyText}${temp}`;
  };
  return (
    <Wrapper>
      <WrapperHR />
      <HeaderTitle>Additonal Documents</HeaderTitle>
      <HeaderText>
        Additonal Documents you want to submit with your{" "}
        <HeaderTextColored>
          {getFormatedFormName(getType || "")}
        </HeaderTextColored>{" "}
        form.
      </HeaderText>

      <Formik
        initialValues={initialValues}
        validationSchema={AdditionalDocumentsValidation}
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          const { file, doc_label, doc_type } = values;
          if (!file || !doc_type) return;
          const docKey = getDocKey(
            doc_type.value === DocTypeOthersValues.Others
              ? doc_label.trim()
              : doc_type.value.trim()
          );
          onSubmit(file, docKey, undefined, () => resetForm());
        }}
      >
        {(formik) => (
          <FieldWrapper>
            {docType && (
              <SelectField
                name="doc_type"
                label="Document Type"
                options={docType}
              />
            )}
            {formik.values.doc_type?.value === DocTypeOthersValues.Others && (
              <InputField
                name="doc_label"
                placeholder=""
                label="Document Name"
              />
            )}
            <BtnWrapper>
              <UploadBox
                name={"file"}
                formik={formik}
                acceptedFormat={"image/*,.pdf"}
                // maxFileSizeInBytes={OpinionMaxFileSize}
                btnText="Upload"
              />

              {formik.values.file && (
                <Btn type="submit">
                  {getIsLoading(getDocKey(formik.values.doc_label)) && (
                    <Spinner color={Colors.White} size={14} />
                  )}
                  Save
                </Btn>
              )}
            </BtnWrapper>
          </FieldWrapper>
        )}
      </Formik>
    </Wrapper>
  );
};

export default AdditionalDoc;

const Wrapper = styled.div`
  /* margin: 1rem 0; */
`;

const WrapperHR = styled(HR)`
  margin: 1rem 0;
`;

const HeaderTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
`;

const HeaderText = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  margin: 0.5rem 0;
  margin-bottom: 1rem;
`;

const HeaderTextColored = styled.span`
  font-weight: 700;
  color: ${Colors.Blue00};
  text-transform: uppercase;
`;

const FieldWrapper = styled(Form)`
  display: flex;
  /* align-items: flex-start; */
  align-items: center;
  gap: 24px;
  width: 70%;

  ${mediaObj.smallDesktop} {
    width: 100%;
  }

  ${mediaObj.bigMobile} {
    flex-wrap: wrap;
  }
`;

const Btn = styled.button`
  text-transform: uppercase;
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 0.75rem;
  color: ${Colors.White};
  background-color: ${Colors.Blue00};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 30px;

  ${mediaObj.bigMobile} {
    flex-wrap: wrap;
    margin-top: 0px;
  }
`;

import React, { FC } from "react";
import styled from "styled-components";
import Colors from "../../../../../../../styles/Colors";
import { createPortal } from "react-dom";
import Overlay from "../../../../../checkEligibilty/components/modals/Overlay";
import { ContainerVariants } from "../../../../../checkEligibilty/components/modals/variant";
import { motion } from "framer-motion";
import { mediaObj } from "../../../../../../../styles/Media";
import { ImCancelCircle } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { ProfileSections } from "../../../types";

interface Props {
  onClose: () => void;
}
const StaffValModal: FC<Props> = ({ onClose }) => {
  const navigate = useNavigate();

  const onGoToBilling = () => {
    navigate(`?active=${ProfileSections.billing}`);
  };

  return createPortal(
    <Overlay onClose={onClose}>
      <Wrapper
        onClick={(e) => e.stopPropagation()}
        variants={ContainerVariants}
      >
        <Cancel onClick={onClose}>
          <ImCancelCircle />
        </Cancel>
        <Header>Maximum Staff Limit Reached!</Header>

        <ContentWrapper>
          <Text>
            Your current plan includes access to 1 staff seat, which is already
            in use. You can add up to 2 more staff seats at $20/month each.
          </Text>

          <CTA onClick={onGoToBilling}>Go To Billing</CTA>
        </ContentWrapper>
      </Wrapper>
    </Overlay>,
    document.body
  );
};

export default StaffValModal;

const Wrapper = styled(motion.div)`
  width: 500px;
  height: max-content;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 12px;
  padding: 40px 32px;
  position: relative;

  ${mediaObj.smallDesktop} {
    margin: auto;
    width: 60vw;
    padding-left: 5%;
    padding-right: 5%;
  }

  ${mediaObj.tablet} {
    width: 80vw;
  }

  ${mediaObj.bigMobile} {
    width: 90vw;
  }
`;

const Cancel = styled.div`
  position: absolute;
  right: 20px;
  top: 18px;
  width: 24px;
  height: 24px;
  color: ${Colors.Black};
  cursor: pointer;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const Header = styled.h2`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.625rem;
  text-align: center;
  color: #1b092c;
  margin-bottom: 1rem;
`;

const ContentWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Text = styled.span`
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
`;

const Btn = styled.button`
  width: max-content;
  height: 46px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 24px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  white-space: nowrap;
  text-transform: uppercase;
  margin: auto;

  & > svg {
    width: 20px;
    height: 20px;
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const CTA = styled(Btn)`
  background: ${Colors.Blue00};
  color: ${Colors.WhiteF9};
  padding: 18.5px 47px;

  &:hover:not(:disabled) {
    background: #022e58;
    color: ${Colors.WhiteF9};
    transition: 0.3s ease-in-out;
  }
`;

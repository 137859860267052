import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Colors from "../../../../../../styles/Colors";
import { Form, Formik } from "formik";
import { SingleOption } from "../../../../../../types/types";
import { NewApplicationValidation } from "../../validation";
import SelectField from "../../../../components/formik fields/SelectField";
import useIsPaid from "../../../../../../hooks/useIsPaid";
import { WebPaths } from "../../../../../../routes/data";
import { newApplicationData } from "../../data";
import useBasedOnFormPaid from "../../../../../../hooks/useBasedOnFormPaid";
import { FormDS160Progress } from "../../../../form ds 160/components/types";

interface FormProps {
  newApp: SingleOption | null;
}

const NewApplication = () => {
  const { getIsPaid } = useIsPaid();
  const { getBasedOnFormPaid } = useBasedOnFormPaid();
  const navigate = useNavigate();

  const startNewApplication = (values: FormProps) => {
    const formType = values.newApp?.value;
    if (!formType) return;
    const isFormPaid = getIsPaid(formType);
    const based = getBasedOnFormPaid(formType);
    if (isFormPaid) {
      if (based) {
        navigate(
          `${WebPaths.FormCategory}/${formType}?type=${formType}-review`
        );
      } else
        navigate(
          `${WebPaths.FormCategory}/${formType}?type=${FormDS160Progress.personalInfoP1}`
        );
    } else navigate(`${WebPaths.Eligibility}/${formType}`);
  };

  // const getApplicationsData = () => {
  //   const data: {
  //     label: JSX.Element;
  //     value: string;
  //   }[] = [];

  //   NavItems.forEach((item) => {
  //     item.children?.forEach((child) => {
  //       child.subChildren.forEach((subChild) => {
  //         if (subChild.label && subChild.url) {
  //           data.push({
  //             label: subChild.label,
  //             value: subChild.formType || "",
  //           });
  //         }
  //       });
  //     });
  //   });

  //   return data;
  // };

  return (
    <Wrapper>
      <HeadText>Start a New Immigration Application</HeadText>
      <SubText>Choose the type of visa you are applying for:</SubText>
      <Formik
        initialValues={
          {
            newApp: null,
          } as FormProps
        }
        validationSchema={NewApplicationValidation}
        onSubmit={startNewApplication}
      >
        <Form className="w-full ">
          <FormContents>
            <SelectField
              name="newApp"
              placeholder="-Select-"
              isRequired
              options={newApplicationData}
              // options={getApplicationsData()}
              height={"47px"}
            />
            <StartBtn type="submit">Start</StartBtn>
          </FormContents>
        </Form>
      </Formik>
    </Wrapper>
  );
};

export default NewApplication;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HeadText = styled.h1`
  font-size: 2rem;
  font-weight: bold;
`;

const SubText = styled.p`
  font-size: 1.25rem;
  margin-top: 20px;
  margin-bottom: 32px;
`;

const FormContents = styled.div`
  /* max-width: 580px; */
  /* height: 47px; */
  min-height: 47px;
  display: flex;
  align-items: center;
  gap: 23px;
  flex-direction: column;
`;

const StartBtn = styled.button`
  width: max-content;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 41px;
  background-color: ${Colors.Blue00};
  border-radius: 0.5rem;
  transition: 0.3s all ease;
  color: #fff;
  font-weight: 600;

  &:hover {
    background: #022e58;
  }
`;

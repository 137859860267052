import { BusinessBoxWrapper, SingleCaseBoxHeader } from "./styles";
import { FC } from "react";
import DocumentsTable from "./DocumentsTable";
import { FileDetail } from "../../../../../../../api/n400/types";
import styled from "styled-components";

interface Props {
  data: FileDetail[];
  isAgent?: boolean;
}

const DocInfo: FC<Props> = ({ data, isAgent }) => {
  return (
    <Wrapper>
      <SingleCaseBoxHeader>Document Information</SingleCaseBoxHeader>
      <DocumentsTable data={data} isAgent={isAgent} />
    </Wrapper>
  );
};

export default DocInfo;

const Wrapper = styled(BusinessBoxWrapper)``;
